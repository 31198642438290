import React, { useEffect, useState } from "react";

import {Pattern} from "@amcharts/amcharts4/core";
import {useDispatch, useSelector} from "react-redux";
import {getOTAs, addOTAs} from "../../../redux/actions/admin/ota";
import {decrypt} from "../../../utils/encryption";

const Ota = () => {

  const [addModal, setAddModal] = useState(false);
  const [otaFileReceived, setOtaFileReceived] = useState([]);
  const {userDetails, selectedUser} = useSelector((state) => {return (state.user)});
  const {ota} = useSelector((state) => {return (state.ota)});
  const [name, setName]= useState("");
  const [version, setVersion]= useState("");
  const [device, setDevice]= useState("");
  const [uploadedFile, setUploadFile]= useState("");
  const [description, setDescription]= useState("");
  const [status, setStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);



  const dispatch = useDispatch();
useEffect(()=>{
  fetchOtas();
},[]);
useEffect(()=>{// console.log("Change Detaacted", otaFileReceived);
// console.log("Selected Detaacted", selectedFile);

},[otaFileReceived, selectedFile])


const fetchOtas = async () => {
    let ress =await dispatch(getOTAs());
// console.log("RESS :", ress);
// setOtaData(ress);


};

const getUrl=(link)=>{
  let linkGenerated = decrypt(link).then((decrypted)=>{
    // console.log("Decreypted :", decrypted);
    // linkGenerated = JSON.parse(decrypted);
    // // console.log("User", linkGenerated);
setOtaFileReceived(decrypted);
  });
  // return linkGenerated;



}
// Function to handle the form submission
const addOTA = async (e) => {
  e.preventDefault();

  try {
    const formData = new FormData();

    // Append the file and form fields to the formData object
    formData.append("file", selectedFile);
    formData.append("name", name);
    formData.append("version", version);
    formData.append("device", device);
    formData.append("status", status ? "active" : "in-active");

    // console.log("FormData", formData);

    // Call the Redux action to make the API request
    const ress = await dispatch(addOTAs(formData));

  } catch (e) {
    console.error("Error:", e);
  }
}
const fileUpload =async (event) => {
    const file = event.target.files[0];
     // Get the selected file

    if (file && file.name.endsWith('.hex')) {
      setSelectedFile(file);
    } else {
      alert('Please select a .hex file.');
    }
  };

    return (
<>
{userDetails && userDetails.user && (userDetails.user.role.includes(1)===true || userDetails.user.role.includes(0)===true)?

        <div className="row mw-100 m-auto h-100 bg-white p-0">
          <div className="col-12 mb-3">
            <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true)}}>Add OTA</button>
          </div>
          <div className="col-12">
            <table className="table table-hover" id="task-table">
                <thead>
                  <tr>
                    <th>Date/Time</th>
                    <th>Version</th>
                    <th>Description</th>
                    <th>File</th>
                  </tr>
                </thead>
                <tbody>
                  { ota && ota.firmwares && ota.firmwares.length > 0 && ota.firmwares.map((row, index)=>(
                      <tr key={index}>
                        <td>{row.createdAt}</td>
                        <td> {row.version}</td>
                        <td>{getUrl(row.link)}</td>
                        <td><a href={`https://int.e-ostre.com:8443`+ `${otaFileReceived}`}>Download</a></td>
                      </tr>
                      ))}
                  </tbody>
            </table>
          </div>
        </div>:<div className="row mw-100 m-auto">
          <div className="col-12">
          <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>
          </div></div>}
        <div className={`modal big_modal_dialog ${addModal? "d-block modal-fading" : " d-none"}`}
              tabIndex="-1"
              data-backdrop="static"
              data-keyboard="false"

              role="dialog"

              aria-hidden="true"
          >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addHospitalModalTitle">
                    Add OTA
                  </h5>
                  <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={()=>setAddModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <form className="pl-3 pr-3">
                  <div className="container my-4 ">


                    <div className="row justify-content-center align-items-center mb-3 text-white">

                      <div className="col-md-2 registration-input-labels"><p className="text-black">Name</p></div>
                      <div className="col-md-10">
                        <input


                          type="text"
                          className="registration-form-control text-black"
                          onChange={(e)=>setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>



                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Version</p></div>
                      <div className="col-md-10">
                        <input
                          type="number"
                          onChange={(e)=>setVersion(e.target.value)}


                          className="registration-form-control text-black"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Device</p></div>
                      <div className="col-md-10">
                        <input
                          type="text"
                          className="registration-form-control text-black"
                          onChange={(e)=>setDevice(e.target.value)}

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Status</p></div>
                      <div className="col-md-10">
                        {/*<input
                          type="number"


                          className="registration-form-control text-black"

                          required
                        />*/}
                        <label className="switch">
                          <input type="checkbox" name="high" defaultChecked={false} onClick={(e)=>{setStatus(!status)}} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Description</p></div>
                      <div className="col-md-10">
                        <textarea
                          type="number"
                          className="registration-form-control text-black"
                          onChange={(e)=>setDescription(e.target.value)}

                          required
                        />

                      </div>
                    </div>







                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels "><p className="text-black">File</p></div>
                      <div className="col-md-10">
                        <div className="styled-select">
                          <input
                          type="file"
                          className="registration-form-control"
                          onChange={(e) => {
                            fileUpload(e);
                          }}
                          required
                          />
                        </div>

                      </div>
                    </div>

                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-primary" type="buttom" onClick={(e)=>addOTA(e)}>Add OTA</button>
                  </div>
                </form>
                </div>
              </div>
          </div>
          </>



    );
};

export default Ota;
