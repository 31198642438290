import React, { useEffect, useState,useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { decrypt } from "../../../utils/encryption";
import { default as Titles } from "../../../utils/document-titles";
import { fetchSessionsParams, updateSessionsParams} from "../../../redux/actions/admin/admin";
export const useMountEffect = (fun) => useEffect(fun, []);


// Gneral Focus Hook
const UseFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

	return [ htmlElRef,  setFocus ]
}

const SessionsParameters = () => {
  document.title = Titles.sessionParams;

  const history = useNavigate();
  const [input1Ref, setInput1Focus] = UseFocus();
  const [isLoading, setIsLoading] = useState(false);
  const [ovsRate, setOvsRate] = useState();
  const [intermissionTime, setIntermissionTime] = useState();
  const [pointSampling, setPointSampling] = useState();
  const [safeGuardVoltage, setSafeGuardVoltage] = useState();
  const [measuringPeriod, setMeasuringPeriod] = useState();
  const [noOfPoints, setNoOfPoints] = useState();
  const [samplingPointDelay, setSamplingPointDelay] = useState();
  const [noOfMeasurement, setNoOfMeasurement] = useState();
  const [scanType, setScanType] = useState();
	const [defaultDeltaTime, setDefaultDeltaTime] = useState();
  const [minimumDeltaTime, setMinimumDeltaTime] = useState();
  const [deltaUintToDeltaT1, setDeltaUintToDeltaT1] = useState();
	const [ksg, setKsg] = useState();
  const [t2ToEomp, setT2ToEomp] = useState();
  const [saturationVoltage, setSaturationVoltage] = useState();
  const dispatch = useDispatch();
useEffect(() => {
fetchingSessionsParams();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])


// getting paramas
const fetchingSessionsParams=async()=>{
  let result = await dispatch(fetchSessionsParams());


  if(result && result.status && result.status === 200) {

    let data = await decrypt(result.data.data);
    data = JSON.parse(data);
    setOvsRate(data.ovsRate);
    setIntermissionTime(data.intermissionTime);
    setPointSampling(data.pointSampling);
    setSafeGuardVoltage(data.safeGuardVoltage);
    setMeasuringPeriod(data.measuringPeriod);
    setNoOfPoints(data.noOfPoints);
    setSamplingPointDelay(data.samplingPointDelay);
    setNoOfMeasurement(data.noOfMeasurement);
    setScanType(data.scanType);
		setDefaultDeltaTime(data.defaultDeltaTime);
		setMinimumDeltaTime(data.minimumDeltaTime);
		setDeltaUintToDeltaT1(data.deltaUintToDeltaT1);
		setKsg(data.ksg);
		setT2ToEomp(data.T2ToEomp);
		setSaturationVoltage(data.saturationVoltage);


  }
}


  const handleUserUpdate = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      let result = await dispatch(
        updateSessionsParams({
          ovsRate:ovsRate,
          intermissionTime:parseInt(intermissionTime),
          pointSampling:parseInt(pointSampling),
          safeGuardVoltage:parseInt(safeGuardVoltage),
          measuringPeriod:parseInt(measuringPeriod),
          noOfPoints:parseInt(noOfPoints),
          samplingPointDelay:parseInt(samplingPointDelay),
          noOfMeasurement:parseInt(noOfMeasurement),
          scanType:parseInt(scanType),
					defaultDeltaTime:parseInt( defaultDeltaTime),
				  minimumDeltaTime:parseInt( minimumDeltaTime),
				  deltaUintToDeltaT1:parseInt( deltaUintToDeltaT1),
				  ksg:parseInt( ksg),
				  T2ToEomp:parseInt( t2ToEomp),
				  saturationVoltage:parseInt( saturationVoltage),

        })
      );
      setIsLoading(false);



      // ShowToast(result.data.message, "success");
    } catch (err) {
      ////(err.response)

      // ShowToast(err.response.data.message, "error");
      setIsLoading(false);
    }
  };
  const validate=(val)=> {
    // // console.log("Val", val);
  let v1 = document.getElementById("name");


  let v2 = document.getElementById("authorisation");
  let v3 = document.getElementById("dob");
  let v4 = document.getElementById("mob");
  let v5 = document.getElementById("address_line_1");
  let v6 = document.getElementById("address_line_2");
  let v7 = document.getElementById("postalcode");
  let v8 = document.getElementById("countrycode");
  let v9 = document.getElementById("scanType");
	let v10 = document.getElementById("defaultDeltaTime");
	let v11 = document.getElementById("minimumDeltaTime");
	let v12 = document.getElementById("deltaUintToDeltaT1");
	let v13 = document.getElementById("ksg");
	let v14 = document.getElementById("T2ToEomp");
	let v15 = document.getElementById("saturationVoltage");




  // let v6 = document.getElementById("ans");

  let flag1 = true;
  let flag2 = true;
  let flag3 = true;
  let flag4 = true;
  let flag5 = true;
  let flag6 = true;
  let flag7 = true;
  let flag8 = true;
  let flag9 = true;
	let flag10 = true;
	let flag11 = true;
	let flag12 = true;
	let flag13 = true;
	let flag14 = true;
	let flag15 = true;




  if(val===1) {
  if(v1.value === "") {
  v1.style.borderColor = "#b78181";
  flag1 = false;
  }
  else {
  v1.style.borderColor = "#1f3c90";
  flag1 = true;
  }
  }

  if(val===2) {
  if(v2.value === "") {
  v2.style.borderColor = "#b78181";
  flag2 = false;
  }
  else {
  v2.style.borderColor = "#1f3c90";
  flag2 = true;
  }
  }
  if(val===3) {
  if(v3.value === "") {
  v3.style.borderColor = "#b78181";
  flag3 = false;
  }
  else {
  v3.style.borderColor = "#1f3c90";
  flag3 = true;
  }
  }
  if(val===4) {
  if(v4.value === "") {
  v4.style.borderColor = "#b78181";
  flag4 = false;
  }
  else {
  v4.style.borderColor = "#1f3c90";
  flag4 = true;
  }
  }
  if(val===5) {
  if(v5.value === "") {
  v5.style.borderColor = "#b78181";
  flag5 = false;
  }
  else {
  v5.style.borderColor = "#1f3c90";
  flag5 = true;
  }
  }
  if(val===6) {
  if(v6.value === "") {
  v6.style.borderColor = "#b78181";
  flag6 = false;
  }
  else {
  v6.style.borderColor = "#1f3c90";
  flag6 = true;
  }
  }
  if(val===7) {
  if(v7.value === "") {
  v7.style.borderColor = "#b78181";
  flag7 = false;
  }
  else {
  v7.style.borderColor = "#1f3c90";
  flag7 = true;
  }
  }
  if(val===8) {
  if(v8.value === "") {
  v8.style.borderColor = "#b78181";
  flag8 = false;
  }
  else {
  v8.style.borderColor = "#1f3c90";
  flag8 = true;
  }
  }
  if(val===9) {

  if(v9.value === "") {
  v9.style.borderColor = "#b78181";
  flag9 = false;
  }
  else {
  v9.style.borderColor = "#1f3c90";
  flag9 = true;
  }
  }


	if(val===10) {
	if(v10.value === "") {
	v10.style.borderColor = "#b78181";
	flag10 = false;
	}
	else {
	v10.style.borderColor = "#1f3c90";
	flag10 = true;
	}
	}

	if(val===11) {
	if(v11.value === "") {
	v11.style.borderColor = "#b78181";
	flag11 = false;
	}
	else {
	v11.style.borderColor = "#1f3c90";
	flag11 = true;
	}
	}

	if(val===12) {
	if(v12.value === "") {
	v12.style.borderColor = "#b78181";
	flag12 = false;
	}
	else {
	v12.style.borderColor = "#1f3c90";
	flag12 = true;
	}
	}

	if(val===13) {
	if(v13.value === "") {
	v13.style.borderColor = "#b78181";
	flag13 = false;
	}
	else {
	v13.style.borderColor = "#1f3c90";
	flag13 = true;
	}
	}

	if(val===14) {
	if(v14.value === "") {
	v14.style.borderColor = "#b78181";
	flag14 = false;
	}
	else {
	v14.style.borderColor = "#1f3c90";
	flag14 = true;
	}
	}

	if(val===15) {
	if(v15.value === "") {
	v15.style.borderColor = "#b78181";
	flag15 = false;
	}
	else {
	v15.style.borderColor = "#1f3c90";
	flag9 = true;
	}
	}

  let flag = flag1 && flag2 && flag3 && flag4 && flag5 && flag6 && flag7 && flag8 && flag9 && flag10 && flag11 && flag12 && flag13 && flag14 && flag15;

  return flag;
  }


  useMountEffect( setInput1Focus );

  return (
    <section className="row mw-100 ml-auto sessions-params mr-auto">

<div className="col-12 col-md-9 m-auto">
<form className="form-card" onSubmit={handleUserUpdate}>
<div className="card">
<div className="row justify-content-center pb-1 pt-1 text-left mw-100 m-auto">
<div className="col-12 p-3">
<h4 className="font-bold">Sessions Parameters</h4></div>
</div>

</div>
              <div className="card mt-3">
																	<div className="row">
																	<div className="col-8 m-auto mb-5 d-flex justify-content-between">
																	<p><span className="">L</span> : <span className="">L-Scan</span></p>
																	<p><span className="">Z</span> : <span className="">Z-Scan</span></p>
																	<p><span className="">M</span> : <span className="">Modus Scan</span></p>

																	</div>
																	</div>
                                  <div className="row justify-content-center pb-1 pt-2 text-left">

                                  <div className="form-group col-12 col-md-5 flex-column d-flex">
                                  <label className="form-control-label">Scan Type<span className="" style={{marginLeft:"4%"}}>(L=1, Z=2, M=3)</span> </label>


                                  <input

                                    defaultValue={scanType}
                                    onChange={(e) => setScanType(e.target.value)}
                                    type="number"
                                    id="scanType"
                                    name="ScanType"
                                    required
                                    onBlur={()=>validate(9)}
                                    className="registration-form-control"


                                  />
                                  </div>



                                  <div className="form-group col-12 col-md-5 flex-column d-flex">
                                  <label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(L, Z, M)</span>ovsRate <span className="" style={{marginLeft:"3%"}}>[power of 2]</span></label>

                                  <input

                                    defaultValue={ovsRate}
                                    onChange={(e) => setOvsRate(e.target.value)}
                                    type="number"
                                    id="authorisation"
                                    name="ovsRate"
                                    required
                                    onBlur={()=>validate(2)}
                                    className="registration-form-control"


                                  />
                                  </div>



                                  </div>
                                  <div className="row justify-content-center pb-1 pt-2 text-left">
                                  <div className="form-group col-sm-5 flex-column d-flex"> <label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(L, Z)</span>PointSampling <span className="" style={{marginLeft:"3%"}}>[ms]</span></label>
                                   <input
                                   required

                                     defaultValue={pointSampling}
                                     onChange={(e) => setPointSampling(e.target.value)}
                                     type="number"
                                     id="dob" name="dob"
                                     onBlur={()=>validate(3)}
                                     className="registration-datePicker"
                                     style={{maxHeight:"45px"}}



                                   />
                                   </div>
                                      <div className="form-group col-sm-5 flex-column d-flex"> <label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(L)</span>Safe Guard Voltage <span className="" style={{marginLeft:"3%"}}>[mV]</span></label>
                                      <input
                                      required
                                        defaultValue={safeGuardVoltage}
                                        onChange={(e) => setSafeGuardVoltage(e.target.value)}
                                        type="number"
                                        className="registration-form-control"
                                        id="mob" name="mob"

                                        onBlur={()=>validate(4)}

                                      />
                                       </div>

                                  </div>
                                  <div className="row justify-content-center pb-1 pt-2 text-left">
                                  <div className="form-group col-sm-5 flex-column d-flex">
                                  <label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(L, Z, M)</span>Measurement Period <span className="" style={{marginLeft:"3%"}}>[s]</span></label>
                                  <input
                                    required
                                    defaultValue={measuringPeriod}
                                    onChange={(e) => setMeasuringPeriod(e.target.value)}
                                    type="number"
                                    className="registration-form-control"
                                    id="address_line_1" name="address_line_1" onBlur={()=>validate(5)}


                                  />
                                  </div>
                                      <div className="form-group col-sm-5 flex-column d-flex">
                                      <label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(L)</span>No Points <span className="" style={{marginLeft:"3%"}}>[-]</span></label>
                                      <input
                                      required
                                      defaultValue={noOfPoints}
                                      onChange={(e) => setNoOfPoints(e.target.value)}
                                        type="number"
                                        className="registration-form-control"
                                        id="address_line_2" name="address_line_2" onBlur={()=>validate(6)}


                                      />
                                      </div>

                                  </div>
                                  <div className="row justify-content-center pb-1 pt-2 text-left">
                                  <div className="form-group col-sm-5 flex-column d-flex">
                                  <label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(L, Z)</span>Sampling Point Delay <span className="" style={{marginLeft:"3%"}}>[ms]</span></label>
                                  <input
                                  required

                                    defaultValue={samplingPointDelay}
                                    onChange={(e) => setSamplingPointDelay(e.target.value)}
                                    type="number"
                                    className="registration-form-control"
                                    id="postalcode" name="postalcode" onBlur={()=>validate(7)}

                                  />
                                   </div>

                                       <div className="form-group col-12 col-sm-5 flex-column d-flex">
                                       <label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(L, Z)</span>No Measurement <span className="" style={{marginLeft:"3%"}}>[-]</span></label>
                                       <input
                                       required

                                         defaultValue={noOfMeasurement}
                                         onChange={(e) => setNoOfMeasurement(e.target.value)}
                                         type="number"
                                         className="registration-form-control"
                                         id="postalcode" name="postalcode" onBlur={()=>validate(7)}

                                       />

                                    </div>
                                    <div className="form-group col-sm-5 flex-column d-flex">
                                    <label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(L, Z, M)</span>IntermissionTime <span className="" style={{marginLeft:"3%"}}>[ms]</span></label>
                                    <input
                                    required

                                      defaultValue={intermissionTime}
                                      onChange={(e) => setIntermissionTime(e.target.value)}
                                      type="number"
                                      id="name"
                                      name="name"

                                      onBlur={()=>validate(1)}
                                      className="registration-form-control"


                                    />
                                    </div>




																		{/*New fields*/}

																		<div className="form-group col-12 col-sm-5 flex-column d-flex">
																		<label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(M)</span>Default Delta Time <span className="" style={{marginLeft:"3%"}}>[ms]</span></label>
																		<input
																		required

																			defaultValue={defaultDeltaTime}
																			onChange={(e) => setDefaultDeltaTime(e.target.value)}
																			type="number"
																			className="registration-form-control"
																			id="defaultDeltaTime" name="defaultDeltaTime" onBlur={()=>validate(10)}

																		/>

																 </div>
																 <div className="form-group col-sm-5 flex-column d-flex">
																 <label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(M)</span>Minimum Delta Time <span className="" style={{marginLeft:"3%"}}>[ms]</span></label>
																 <input
																 required

																	 defaultValue={minimumDeltaTime}
																	 onChange={(e) => setMinimumDeltaTime(e.target.value)}
																	 type="number"
																	 id="minimumDeltaTime"
																	 name="minimumDeltaTime"

																	 onBlur={()=>validate(11)}
																	 className="registration-form-control"


																 />
																 </div>

																 <div className="form-group col-12 col-sm-5 flex-column d-flex">
																 <label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(M)</span>Delta Uint To Delta T1 <span className="" style={{marginLeft:"3%"}}>[mV]</span></label>
																 <input
																 required

																	 defaultValue={deltaUintToDeltaT1}
																	 onChange={(e) => setDeltaUintToDeltaT1(e.target.value)}
																	 type="number"
																	 className="registration-form-control"
																	 id="deltaUintToDeltaT1" name="deltaUintToDeltaT1" onBlur={()=>validate(12)}

																 />

															</div>
															<div className="form-group col-sm-5 flex-column d-flex">
															<label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(M)</span>Ksg<span className="" style={{marginLeft:"3%"}}>[%]</span></label>
															<input
															required

																defaultValue={ksg}
																onChange={(e) => setKsg(e.target.value)}
																type="number"
																id="ksg"
																name="ksg"

																onBlur={()=>validate(13)}
																className="registration-form-control"


															/>
															</div>


															<div className="form-group col-12 col-sm-5 flex-column d-flex">
															<label className="form-control-label"><span className="" style={{marginRight:"2%"}}>(M)</span>T2ToEomp<span className="" style={{marginLeft:"3%"}}>[s]</span></label>
															<input
															required

																defaultValue={t2ToEomp}
																onChange={(e) => setT2ToEomp(e.target.value)}
																type="number"
																className="registration-form-control"
																id="T2ToEomp" name="T2ToEomp" onBlur={()=>validate(14)}

															/>

													 </div>
													 <div className="form-group col-sm-5 flex-column d-flex">
													 <label className="form-control-label">Saturation Voltage <span className="" style={{marginLeft:"3%"}}>[mV]</span></label>
													 <input
													 required

														 defaultValue={saturationVoltage}
														 onChange={(e) => setSaturationVoltage(e.target.value)}
														 type="number"
														 id="saturationVoltage"
														 name="saturationVoltage"

														 onBlur={()=>validate(15)}
														 className="registration-form-control"


													 />
													 </div>

                                  </div>

                          </div>
                          <div className="row justify-content-center">

                              <div className="form-group col-sm-6 flex-column d-flex mt-3">
                              <Spin spinning={isLoading}>
                                <button
                                  type="Submit"
                                  className={`registration-btn btn-primary `}
                                >
                                  Submit
                                </button>
                              </Spin>
                              </div>
                          </div>
                          </form>

                          </div>

    </section>

  );
};

export default SessionsParameters;
