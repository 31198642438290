import http from "../../utils/http-service";
import { API_BASE_URL, fetchBgDataUrl} from "../../utils/urls";
import {
  FETCH_OGP_START,
  FETCH_OGP_FAILED,
  SAVE_MBG_SUCCESS,
  FETCH_OGP_SUCCESS,

} from "../actionTypes";
import moment from "moment";
import {encrypt, decrypt} from "../../utils/encryption";

export const fetchOgpData = (data) => async(dispatch) => {
  // console.log("DATA", data);
  dispatch({
    type: FETCH_OGP_START,
  });
try {
  // // console.log("Data",data);
    let bg;
    let res;
    let dayDif;
    let type = 4;
    if(data.time){
      bg = data.time;
      // dayDif = data.time;

      bg =await encrypt(bg);


      type = JSON.stringify(type);
      type =await encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${type}?time=${bg}`);
     // // console.log("RES", res);
    } else if(data.start_date) {
      // console.log("IN ELSE range");
      let bg_start = data.start_date;
      bg_start =await encrypt(bg_start);
      let bg_end = data.end_date;
      bg_end =await encrypt(bg_end);
       // dayDif = moment.duration(bg_end.diff(bg_start)).asDays();
            type = JSON.stringify(type);
      type = await encrypt(type);

     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${type}?start_date=${bg_start}&end_date=${bg_end}`,);
     // // console.log("RES range", res);

}
let receivedData = res.data.data;
console.log("receivedData in ogp", receivedData);
// // console.log("receivedData", receivedData);
receivedData =await decrypt(receivedData);
receivedData = JSON.parse(receivedData);

  // updateUnit(unit, res.data.data)
let ogp;

    if(receivedData.overlay && receivedData.overlay.length>0){
      console.log("receivedData in IF", receivedData);

      let len = receivedData.overlay.length;
      let mbg;
      // let cv;
      // let lage;
      // let jIndex;
      let bgVal =[];
      let val=0;
      receivedData.overlay.forEach((x)=>{
        let bloodGlucoseVal = x.value;
        // bloodGlucoseVal = bloodGlucoseVal.toFixed(2);
        bgVal.push(bloodGlucoseVal);
        val = val + x.value;
      });
      mbg = val/ receivedData.overlay.length;
      mbg = mbg.toFixed(2);
      dispatch({ type: SAVE_MBG_SUCCESS, payLoad: mbg });
      // let sd = [];
      // let sdbg;
      // let val2 = 0;
      //
      // receivedData.overlay.forEach((x)=>{
      //   let unit = x.value - mbg;
      //   unit  = Math.pow(unit, 2);
      //   sd.push(unit);
      // });
      // sd.forEach((item, i) => {
      //   val2 = val2 + item;
      // });
      // val2 = val2/len;
      // sdbg = Math.sqrt(val2);
      // sdbg = sdbg.toFixed(2);
      //
      // dispatch({ type: SAVE_SDBG_SUCCESS, payLoad: sdbg });
      // cv = sdbg/mbg;
      // cv = cv * 100;
      // cv = cv.toFixed(2);
      // dispatch({ type: SAVE_CV_SUCCESS, payLoad: cv });
      // let maxBgVal = Math.max(...bgVal);
      // let minBgVal = Math.min(...bgVal);
      // lage = maxBgVal - minBgVal;
      // dispatch({ type: SAVE_LAGE_SUCCESS, payLoad: lage });
      // let mbgsdbg = mbg*sdbg;
      // jIndex = 0.324 * Math.pow(mbgsdbg, 2);
      // jIndex = jIndex.toFixed(2);
      // dispatch({ type: SAVE_JINDEX_SUCCESS, payLoad: jIndex });
      // dispatch(fetchCgmDataForHistory(receivedData.overlay, dayDif));
      let ogpReading =  makeAGPReadings(receivedData.overlay);
      dispatch({ type: FETCH_OGP_SUCCESS, payLoad: ogpReading });
      ogp = ogpReading;
    } else {
      console.log("receivedData", receivedData);
    }



  return ogp;
} catch (error) {
  // // console.log("ERRROR", error);
  dispatch({
    type: FETCH_OGP_FAILED,
  });
}
};


const findMinMax=(arr,key)=> {
const datas = arr.map((node) => node[key]);
return {
  min: Math.min(...datas),
  max: Math.max(...datas),
}
}




const findStartDate = (filter) => {


    if(filter === "30days") {
      let start = moment().subtract(30, 'days');


      return new Date(start._d);

    }else if(filter==="7days") {

      let start = moment().subtract(7, 'days');


      return new Date(start._d);


    } else if (filter === "14days") {


      let start = moment().subtract(14, 'days');

      return new Date(start._d);

    } else if (filter === "90days") {

      let start = moment().subtract(90, 'days');


      return new Date(start._d);

    } else if(filter === "24hours"){

      let start = moment().add(-24, 'hours');


      return new Date(start._d);

    }
    else if(filter === "12hours"){
      let start = moment().add(-12, 'hours');


      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');


      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }


  const makeAGPReadings= (data)=>{
  let eachHourData=[];
  let timeSlots=["00", "01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];

    for(let i =0;i<24; i++){
      let obj = data.filter((dayInner, index)=>{
      return  parseInt(moment(dayInner.time).format("HH")) === parseInt(timeSlots[i])

    });
      obj.sort((a, b)=>{return a.value-b.value});
      eachHourData.push(obj);
    }
  return eachHourData;
}
