import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from 'react-router-dom';


import { Link } from "react-router-dom";
import { Spin } from "antd";
import { getEngineers, fetchEngineerData, fetchSensorLogsData } from "../../../redux/actions/admin/admin";

 import { useNavigate } from "react-router-dom";
import SensorTable from './sensor-table.js';
import Loading from "../../Common/Loading"


import moment from "moment";

const ViewApplicationLogs = (props) => {
  // // // console.log("PROPS TO VIEW ENGINEER", props);
  const {app} = useParams();
let appFinal = "modus";
appFinal = appFinal.replace(':','');
    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();


    // const [role, setRole] = useState("patient");
    const list = useSelector((state) => {return (state.admin.engineers)});
    const {modus2} = useSelector((state) => {return (state.admin)});


    const [instance, setInstance] = useState("");
    const [collapse, setCollapse] = useState(false);
    const [loading,setLoading]=useState(false);
    const [showSave, setShowSave] = useState(false);
    const[engineerData, setEngineerData] = useState();
    const[eData, setEData] = useState([]);
    const[space, setSpace] = useState("\n");





  const { userDetails, selectedUser } = useSelector((state) => {return state.user});
  const [csv, setCsv] = useState("");
  const safari = useSelector((state) => {
      return state.auth.safari;
    });
    useEffect(() => {
// // console.log("MODUS 2 IN EFFECT", modus2);
fetchEngineers();
},[location]);

  //   useEffect(() => {
  //     fetchEngineers();
  // }, [props.role]);







  const convert = (sessionData) => {

    // e.preventDefault();

    // "\uFEFF"+

    let dataNew =[];

    sessionData.map((obj)=>{

      let object = {
        "UserId": obj.userId._id,
        "firstPointSamplingDelay_ms": obj.firstPointSamplingDelay_ms,
        "createdAt": obj.createdAt,
        "flags": obj.flags,
        "intermissionTime_ms":obj.intermissionTime_ms,
        "maxNumberOfPoints": obj.maxNumberOfPoints,
        "measurementPeriod_s":obj.measurementPeriod_s,
        "numberOfMeasurements": obj.numberOfMeasurements,
        "ovsRate": obj.ovsRate,
        "pointSamplingPeriod_ms": obj.pointSamplingPeriod_ms,
        "re_mV":obj.re_mV,
        "safeguardVoltage_mV":obj.safeguardVoltage_mV,
        "scanCase": obj.scanCase,
        "scanType":obj.scanType,
        "updatedAt": obj.updatedAt,
        "wecom_mV": obj.wecom_mV,
        "__v":obj.__v,
        "_id":obj._id,
        "measurementData": obj.measurementData.map((val, index)=>{
          return (
            {
              "batteryLevel":val.batteryLevel,
              "bloodGlucose":val.bloodGlucose,
              "createdAt":val.createdAt,
              "flags":val.flags,
              "index":val.index,
              "sessionId":val.sessionId,
              "temperature":val.temperature,
              "updatedAt":val.updatedAt,
              "userId":val.userId,
              "userPId":val.userPId,
              "we1Current":val.we1Current,
              "we2Current":val.we2Current,
              "__v":val.__v,
              "_id":val._id,
              "pointData":val.pointData && val.pointData.lenth>0 && val.pointData.map((point)=>{
                return ({
                  "createdAt":point.createdAt,
                  "index":point.index,
                  "measurementId":point.measurementId,
                  "timestamp":point.timestamp,
                  "updatedAt":point.updatedAt,
                  "userId":point.userId,
                  "userPId":point.userPId,
                  "we1":point.we1,
                  "we2":point.we2,
                  "__v":point.__v,
                  "_id":point._id,
                })
              })
            })
          })
        }
      dataNew.push(object);

    })

    const parsedJson = dataNew;
    if (
      !Array.isArray(parsedJson) ||
      !parsedJson.every((p) => typeof p === "object" && p !== null)
    ) {
      return;
    }
    const heading = Object.keys(parsedJson[0]).join(",");
    const space = "\n"
    const body = parsedJson.map((j) => Object.values(j).join(",")).join("\n");
    setCsv(`${heading}${space}${body}`);
    let csvData = heading + space + body;

    if(safari){
      download(csvData,"Engineer", 'text/csv;charset=utf-8;');

    }else{
    download("\uFEFF"+csvData,"Engineer", 'text/csv;charset=utf-8;');
    }
  };

                // Function to download data to a file
  const download=( processedData, filename, type)=> {
    let data = processedData;

      var file = new Blob([data], {type: type});
      if (window.navigator.msSaveOrOpenBlob) // IE10+
          window.navigator.msSaveOrOpenBlob(file, filename);
      else { // Others
          var a = document.createElement("a"),
                  url = URL.createObjectURL(file);
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
          }, 0);
      }
  }

      const fetchEngineers = async () => {
        // // console.log("appFinal", appFinal);
      dispatch(getEngineers(3,appFinal))

        };
      const fetchSensorData = async (id, email, mod) => {
        setLoading(true);
        try{
         // const ress= await dispatch(fetchEngineerData(id, email));

         const ress= await dispatch(fetchSensorLogsData(id,email,"modus"));
         if(ress.status=== 200){
           // // console.log("sensorlogs:",ress.data);
          setEData(ress.data.data.logs);
          //  convert(ress.data.data.sessionData);
          // props.onSpiner(false);
          setLoading(false);
          history(`/admin/applicationLogs/applicationLogsData/:${id}/:${email}/:"modus"`);


          // props.onClick(true);


         }

        }catch(error){
          setLoading(false);

        }

      };


      const saveId=(id, email)=>{
          fetchSensorData(id,email);


          // document.getElementById("engineerTab").click();
          //history("/admin/viewEngineerData");
          // dispatch(props.setEData(true))
      }

    return (


        <div className="container-fluid patient-list h-100">
        {userDetails && userDetails.user && (userDetails.user.role.includes(1)===true || userDetails.user.role.includes(0)===true || userDetails.user.role.includes(5)===true)?

                <div className="row p-2 px-3 h-100">
                    <div className="col-12">
                        <div className="row justify-content-between pl-3 h-100">

                            <div className="col-12 col-md-6">
                              <h1 className="pt-3 pb-3 font-weight-bold chart-heading">Engineer's Details</h1>
                            </div>


                            <div className="col-12">
                            {loading?
                            <Loading/>
                            :
                            <table className="table table-hover" id="task-table">
                              <thead>
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th className="text-center">Actions</th>
                              </tr>
                              </thead>
                              <tbody>

                              { list !== null && list.length > 0 && list.map((row, index)=>(
                              <tr key={index+1}>
                                <td>{row.firstName}</td>
                                <td>{row.lastName}</td>
                                <td>{row.email}</td>
                                <td>{row.phone}</td>
                                <td className='text-center'>

                                  <button
                                      type="button"
                                      className=""
                                      data-toggle="modal"
                                      data-target="#addHospitalModal"
                                      onClick={(event)=>{
                                        saveId(row._id, row.email);
                                      }}
                                  >
                                    <span className="">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                    </span>
                                  </button>
                                </td>
                              </tr>
                            ))}

                              </tbody>
                            </table>
                          }
                            </div>





                        </div>

                    </div>
                </div>
                :
                <div className="row mw-100 m-auto">
                  <div className="col-12">
                  <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>
                  </div>
                </div>}

        </div>

    );
};

export default ViewApplicationLogs;














{/*import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Spin } from "antd";


 import { useNavigate, useLocation } from "react-router-dom";
// import {encrypt,decrypt} from "../../../utils/encryption";

import { fetchEngineerData } from "../../../redux/actions/admin/admin";


import moment from "moment";

// import UsersMangement from "../../common/nav/usersmanagement.js";

const ViewEngineer = (props) => {
  const dispatch = useDispatch();
  const[engineerData, setEngineerData] = useState();



  const { userDetails, selectedUser } = useSelector((state) => {return state.user});
  const { eData } = useSelector((state) => {return state.admin});
  const [csv, setCsv] = useState("");
  const safari = useSelector((state) => {
      // setJson(state.admin.users);

      return state.auth.safari;
    });

  useEffect(() => {

 },[eData]);


const getEngineerData =async(id)=> {
dispatch(fetchEngineerData(id));
localStorage.removeItem("eId");


}


const convert = () => {
    // e.preventDefault();

    // "\uFEFF"+

    let dataNew =[];
    eData.data.map((obj)=>{


      let object = {"UserId": obj._id,
       "DeviceId": obj.deviceId, "TimeStamp": obj.createdAt, "Device Temp(Internal)": obj.internalTemperature,
       "Device Temp(External)":obj.externalTemperature, "f0": obj.f0, "Q0":obj.q0,
       "IL": obj.il, "fstart": obj.fstart, "fstop": obj.fstop, "s21": obj.s21.map((val, index)=>{return val})}

      dataNew.push(object);

    })

    const parsedJson = dataNew;
    if (
      !Array.isArray(parsedJson) ||
      !parsedJson.every((p) => typeof p === "object" && p !== null)
    ) {
      return;
    }
    const heading = Object.keys(parsedJson[0]).join(",");
    const space = "\n"
    const body = parsedJson.map((j) => Object.values(j).join(",")).join("\n");
    setCsv(`${heading}${space}${body}`);
    let csvData = heading + space + body;

    if(safari){
      download(csvData,"Engineer", 'text/csv;charset=utf-8;');

    }else{
    download("\uFEFF"+csvData,"Engineer", 'text/csv;charset=utf-8;');
    }
  };

  // Function to download data to a file
const download=( processedData, filename, type)=> {
  let data = processedData;

    let file = new Blob([data], {type: type});
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}





    return (
      <div>
        <div className="">

            <div className="row  justify-content-md-between  justify-content-center  ml-md-3 ml-lg-0">
            {eData && eData.data.length>0 && eData.data.map((row, index)=>(
            <div className="col-12">
              <div className="row">
              <div className="col-12 col-md-3 d-flex justify-content-center align-items-center">
                            <button className="btn btn-primary" onClick={()=>convert()}>Download CSV</button>
                            </div>
                <div className="col-12">
                  <h1>{row.type===1?"Temperature Correction":
                  row.type===2?"User Calibration Study ":
                  row.type===3?"Movement/Pressure Study ":null} </h1>
                </div>
                <div className="col-12">
                <table className="table table-hover" id="task-table">
                  <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Device ID</th>
                    <th>TimeStamp</th>

                    <th>Device Temp(Internal)</th>
                    <th>Device Temp(External)</th>
                    <th>f0</th>

                    <th>Q0</th>
                    <th>IL</th>
                    <th>fstart</th>
                    <th>fstop</th>
                    <th>s21</th>

                  </tr>
                  </thead>
                  <tbody>


                  <tr>
                    <td>{row._id}</td>
                    <td>{row.deviceId}</td>
                    <td>{row.type}</td>
                    <td>{moment(row.createdAt).format("D MMM YYYY")}</td>
                    <td>{row.internalTemperature}</td>
                    <td>{row.externalTemperature}</td>
                    <td>{row.f0}</td>
                    <td>{row.q0}</td>
                    <td>{row.il}</td>
                    <td>{row.fstart}</td>
                    <td>{row.fstop}</td>
                    <td>{row.s21.map((data)=>(data))}</td>







                  </tr>


                  </tbody>
                </table>
                </div>

              </div>
            </div>
          ))}


            </div>

        </div>
      </div>
    );
}

export default ViewEngineer;*/}
