
import React, { useEffect } from "react";
import * as _ from 'lodash';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
// Themes end
function TimeInRange(props) {
  // let chart;

  let chartData = [
    {
      "type": "Very High ",
      "value": _.round(props.data.veryHighEventsPercentage, 1).toFixed(1),
    },{
    "type": "High",
    "value": _.round(props.data.highEventsPercentage, 1).toFixed(1),
  },
  {
    "type": "In Range",
    "value": _.round(props.data.inRangeEventsPercentage, 1).toFixed(1),
  },
  {
    "type": "low",
    "value": _.round(props.data.lowEventsPercentage, 1).toFixed(1),
  },{
    "type": "Very Low",
    "value": _.round(props.data.veryLowEventsPercentage, 1).toFixed(1),
  },];
  // _.round(props.avg.stdDeviation, 1).toFixed(1)
  useEffect(() => {


    let chart = am4core.create(props.chartId, am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;

    chart.data = chartData;

   // Add and configure Series
var pieSeries = chart.series.push(new am4charts.PieSeries());
pieSeries.dataFields.value = "value";
pieSeries.dataFields.category = "type";
pieSeries.dataFields.hidden = "hidden";

// Let's cut a hole in our Pie chart the size of 40% the radius
chart.innerRadius = am4core.percent(40);

// Disable ticks and labels
pieSeries.labels.template.disabled = true;
pieSeries.ticks.template.disabled = true;

// // Disable tooltips
// pieSeries.slices.template.tooltipText = "";

// Add a legend
chart.legend = new am4charts.Legend();
chart.legend.position = "right";
chart.legend.maxWidth = 140;
chart.legend.fontSize="8px"

var marker = chart.legend.markers.template.children.getIndex(0);
marker.cornerRadius(12, 12, 12, 12);
marker.strokeWidth = 2;
marker.strokeOpacity = 1;
marker.stroke = am4core.color("#ccc");


}, [props.data]);

  return (<div className="">

        <div id={props.chartId} style={{ width: "100%", minHeight: "200px" }}></div>
  </div>
  );
}
export default TimeInRange;
