import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
 import { useNavigate, useLocation } from "react-router-dom";
import AllFilters from "../Components/Common/Filters";

import OgpChart from "../Components/Reports/Charts/OgpChart";
import {fetchOgpData} from "../redux/actions/ogp";
import {getDevices} from "../redux/actions/device";
import * as _ from "lodash";
import moment from "moment";
import {Spin, Menu, Dropdown, Button, DatePicker} from "antd";


const OgpPage = () => {
  const { RangePicker } = DatePicker;
  const history = useNavigate();

  const userData = useSelector((state) => state.user);
  const {settings} = useSelector((state) => state.settings);

  // // console.log("userDATA :", userData);
  if(userData.userDetails && userData.userDetails.user && userData.userDetails.user.role.length>1){
   history("/dashboard");
  }

  const data = useSelector((state) => {

    return (state.overlay)});

  const unit = useSelector((state)=> state.devices.device!== undefined?state.devices.device:null);
  const [filterText, setFilterText] = useState("Choose Data Range");
  const [filter, setFilter] = useState("30 days");
  const [isLoading, setIsLoading] = useState(true);
  const [organizedData, setOrganizedData] = useState([]);
  const [daysDif, setDaysDif] = useState("");
  const [chartData, setChartData] = useState([]);

  const [showDate, setShowDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [todayFilter, setTodayFilter] = useState();
  const [weekFilter, setWeekFilter] = useState();
  const [monthFilter, setMonthFilter] = useState(true);
  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu,setDropMenu]=useState(false);




  const dispatch = useDispatch();

  useEffect(() => {

    if (filter !== "" || startDate !== "") {
      // console.log("FILTER", filter);
      fetchReadings();
    }
  }, []);
  useEffect(() => {
    // console.log("FILTER IN USE:", filter);
    if(filter !=="" && startDate === ""){
      // fetchFilterReadings();
    }
  }, [filter, startDate]);

  // useEffect(() => {
  //   transformAverageBgData();
  // }, [data.averageBg]);

  // useEffect(() => {
  //   transformBgReading();
  // }, [data.bgReadings]);

  // useEffect(() => {
  //   if (data.timeRange.length > 0) {
  //     transformTimeRange();
  //   }
  // }, [data.timeRange]);

  const fetchReadings = async () => {
    let data = {};
    if (filter) {
      // console.log("FILER IN IF", filter);
      data.time = filter;

    } else {
      // console.log("FILTER IN ELSE", filter);
    }
    if (startDate) {

      data.start_date = startDate;
      data.end_date = endDate;
    }


      let ress =await dispatch(fetchOgpData(data));
// console.log("RESS :", ress);
setChartData(ress);
    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  } else if (rangFilter === true) {

  }

  };




  const findStartDate = (filter) => {

    let currentDate = new Date();

    if(filter === "30days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {
      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "14days") {

      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days") {

      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }

  const fetchRangeReadings=async()=>{
    setFilter("");
    if(startDate !=="" && endDate !==""){
      let a = moment(startDate);
      let b = moment(endDate);
      let dif = moment.duration(b.diff(a)).asDays();
      let finalDif = dif>1? dif + " Days": dif + " Day";
      // // // // console.log(typeof(finalDif));

      setDaysDif(finalDif);
    setIsLoading(true);

    setOrganizedData([]);



  let data = {};



    data.start_date = startDate;
    data.end_date = endDate;



    const ress = await dispatch(fetchOgpData(data));
setChartData(ress);
  if (rangFilter === false){
  let start = findStartDate(filter);

  setFromDate(moment(start).format('D MMM YYYY').toString());
  setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
} else if (rangFilter === true) {

  setFromDate(moment(startDate).format('D MMM YYYY').toString());
  setToDate(moment(endDate).format('D MMM YYYY').toString());
}
  }
  }

  const fetchFilterReadings=async(e)=>{
    // console.log("event", e.target);
    setStartDate("");
    setEndDate("");
    //setShowDate(false);
    setFilter(e.target.name);
    setFilterText(e.target.name);
    // setWeekFilter(true);
    // setTodayFilter(false);
    // setMonthFilter(false);
    setRangeFilter(false);
    setIsLoading(true);
    setDropMenu(false);


    if(filter !== e.target.name){
      setOrganizedData([]);
    }


  let data = {};

    data.time = e.target.id;



    const ress = await dispatch(fetchOgpData(data));
setChartData(ress);

  if (rangFilter === false){
  let start = findStartDate(e.target.name);

  setFromDate(moment(start).format('D MMM YYYY').toString());
  setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
} else if (rangFilter === true) {

}


  }

    return (
      <div className="container-fluid">
      <div className="row  mr-sm-0 sticky-top">
        <div className="col-12 mt-md-0 mt-2"  style={{ height: "100%" }}>
          <div className="bg-white rounded shadow " style={{ height: "100%" }}>
            {/*<OverlayFilter/>*/}
            <div className="sticky-top">
              <div className="d-flex justify-content-between mb-2">
                <div className="chart-heading mt-3 loginNew-HCP-panel">
                  <span className="bg-primary px-2 py-2 font-size2 font-weight-bold text-white">
                    OGP
                  </span>
                </div>

              </div>
              <AllFilters filter={filter} rangFilter={rangFilter} setRangeFilter={setRangeFilter}
              setFilter={setFilter}
               daysDif={daysDif} fromDate={fromDate} toDate={toDate}
               startDate={startDate} fetchFilterReadings={fetchFilterReadings}
               fetchRangeReadings={fetchRangeReadings} startDate={startDate}
               endDate={endDate} dropMenu={dropMenu} setDropMenu={setDropMenu}
               setStartDate={setStartDate} setEndDate={setEndDate} filterText={filterText} setFilterText={setFilterText} showDays="" showRange={true}/>
            </div>
            </div>
          </div>
        </div>



      <div className="row  mr-sm-0  mt-2 justify-content-center">
        <div className=" col-md-12" style={{ height: "100%" }}>
          <div className="bg-white rounded shadow " style={{ height: "100%" }}>
            <OgpChart data={chartData} low={settings.low} high={settings.high}/>

          </div>
        </div>
      </div>
    </div>
    );
};

export default OgpPage;
