import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
 import { useNavigate, useLocation } from "react-router-dom";
import OverlayChart from "../Components/Reports/Charts/OverlayChart";
import OverlayTestChart  from "../Components/Reports/Charts/overlayTestChart";

import AllFilters from "../Components/Common/Filters";
import Loading from '../Components/Common/Loading';


import {fetchOverlayData} from "../redux/actions/overlay";
import {filterFromDate,filterToDate} from "../redux/actions/user";

import {getDevices} from "../redux/actions/device";
import * as _ from "lodash";
import moment from "moment";
import {Spin, Menu, Dropdown, Button, DatePicker} from "antd";


const OverlayPage = () => {
  const { RangePicker } = DatePicker;
  const history = useNavigate();

  const userData = useSelector((state) => state.user);
  const {isLoading} = useSelector((state) => state.overlay);

  // // console.log("userDATA :", userData);
  if(userData.userDetails && userData.userDetails.user && userData.userDetails.user.role.length>1){
   history("/dashboard");
  }

  const data = useSelector((state) => {

    return (state.overlay)});

  const unit = useSelector((state)=> state.devices.device!== undefined?state.devices.device:null);
  const [filterText, setFilterText] = useState("Choose Data Range");
  const [filter, setFilter] = useState("30 days");
  // const [isLoading, setIsLoading] = useState(true);
  const [organizedData, setOrganizedData] = useState([]);
  const [daysDif, setDaysDif] = useState("");
  const [chartData, setChartData] = useState([]);

  const [showDate, setShowDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [todayFilter, setTodayFilter] = useState();
  const [weekFilter, setWeekFilter] = useState();
  const [monthFilter, setMonthFilter] = useState(true);
  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu,setDropMenu]=useState(false);






  const dispatch = useDispatch();

  useEffect(() => {

    if (filter !== "" || startDate !== "") {
      // console.log("FILTER", filter);
      fetchReadings();
    }
  }, []);

  useEffect(() => {
    // console.log("FILTER IN USE:", filter);

  }, [filter, startDate]);



  const fetchReadings = async () => {
    let data = {};
    if (filter) {
      // console.log("FILER IN IF", filter);
      data.time = "30days";

    } else {
      // console.log("FILTER IN ELSE", filter);
    }
    if (startDate) {

      data.start_date = startDate;
      data.end_date = endDate;
    }


      let ress =await dispatch(fetchOverlayData(data));
console.log("RESS :", ress);
setChartData(ress);
    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
    const fromDat = moment(start).format('D MMM YYYY').toString();
        const toDat = moment(findStartDate()).format('D MMM YYYY').toString();
        dispatch(filterFromDate(fromDat));
        dispatch(filterToDate(toDat));
  } else if (rangFilter === true) {

  }

  };




  const findStartDate = (filter) => {

    let currentDate = new Date();

    if(filter === "30days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {
      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "14days") {

      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days") {

      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }

  const fetchRangeReadings=async()=>{
    setFilter("");
    if(startDate !=="" && endDate !==""){
      let a = moment(startDate);
      let b = moment(endDate);
      let dif = moment.duration(b.diff(a)).asDays();
      let finalDif = dif>1? dif + " Days": dif + " Day";
      // // // // console.log(typeof(finalDif));

      setDaysDif(finalDif);
    // setIsLoading(true);

    setOrganizedData([]);

  let data = {};



    data.start_date = startDate;
    data.end_date = endDate;
    data.unit = userData.userDetails.user.unit;



    let ress =await dispatch(fetchOverlayData(data));
// console.log("RESS :", ress);
setChartData(ress);

  if (rangFilter === false){
  let start = findStartDate(filter);

  setFromDate(moment(start).format('D MMM YYYY').toString());
  setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  const fromDat = moment(start).format('D MMM YYYY').toString();
      const toDat = moment(findStartDate()).format('D MMM YYYY').toString();
      dispatch(filterFromDate(fromDat));
      dispatch(filterToDate(toDat));
  } else if (rangFilter === true) {

  setFromDate(moment(startDate).format('D MMM YYYY').toString());
  setToDate(moment(endDate).format('D MMM YYYY').toString());

  const fromDat = moment(startDate).format('D MMM YYYY').toString();
      const toDat = moment(endDate).format('D MMM YYYY').toString();
      dispatch(filterFromDate(fromDat));
      dispatch(filterToDate(toDat));
  }
  }
  }

  const fetchFilterReadings=async(e)=>{
    // console.log("event", e.target);
    setStartDate("");
    setEndDate("");
    //setShowDate(false);
    setFilter(e.target.name);
    setFilterText(e.target.name);
    // setWeekFilter(true);
    // setTodayFilter(false);
    // setMonthFilter(false);
    setRangeFilter(false);
    // setIsLoading(true);
    setDropMenu(false);


    if(filter !== e.target.name){
      setOrganizedData([]);
    }

  let data = {};

    data.time = e.target.id;
    data.unit = userData.userDetails.user.unit


    let ress =await dispatch(fetchOverlayData(data));
// console.log("RESS :", ress);
setChartData(ress);

  if (rangFilter === false){
  let start = findStartDate(e.target.name);

  setFromDate(moment(start).format('D MMM YYYY').toString());
  setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  const fromDat = moment(start).format('D MMM YYYY').toString();
      const toDat = moment(findStartDate()).format('D MMM YYYY').toString();
      dispatch(filterFromDate(fromDat));
      dispatch(filterToDate(toDat));
  } else if (rangFilter === true) {

  }


  }

  // const transAllReadings = () => {
  //
  //   let transformedData = [];
  //
  //   transformedData = data.historicalReadings
  //       .map((reading) => {
  //
  //
  //             return {
  //               date: new Date(reading.time),
  //               reading: reading.value,
  //             };
  //       })
  //       .flat();
  //
  //   let myArray = _.sortBy(transformedData.flat(), function(dateObj) {
  //
  //     return new Date(dateObj.date);
  //   });
  //
  //   setTfHistoricalReadings(myArray);
  // };
    return (
      <div className="container-fluid">
      <div className="row  mr-sm-0 sticky-top">
        <div className="col-12 mt-md-0 mt-2"  style={{ height: "100%" }}>
          <div className="bg-white rounded shadow " style={{ height: "100%" }}>
            {/*<OverlayFilter/>*/}
            <div className="sticky-top">
              <div className="d-flex justify-content-between mb-2">
                <div className="chart-heading mt-3 loginNew-HCP-panel">
                  <span className="bg-primary px-2 py-2 font-size2 font-weight-bold text-white">
                    Overlay
                  </span>
                </div>

              </div>
              <AllFilters filter={filter} rangFilter={rangFilter} setRangeFilter={setRangeFilter}
              setFilter={setFilter}
               daysDif={daysDif} fromDate={fromDate} toDate={toDate}
               startDate={startDate} fetchFilterReadings={fetchFilterReadings}
               fetchRangeReadings={fetchRangeReadings} startDate={startDate}
               endDate={endDate} dropMenu={dropMenu} setDropMenu={setDropMenu}
               setStartDate={setStartDate} setEndDate={setEndDate} filterText={filterText} setFilterText={setFilterText} showDays="" showRange={true}/>
            </div>
            </div>
          </div>
        </div>

      <div className="row  mr-sm-0  mt-2 justify-content-center">
        <div className=" col-md-12" style={{ height: "100%" }}>
          <div className="bg-white rounded shadow " style={{ height: "100%" }}>
        {/*<OverlayReport/>*/}
        <div className='h-100 d-flex flex-column justify-content-md-between justify-content-center px-3 pt-2'>

        {/* <div>
          <i className="fas fa-ellipsis-h"></i>
        </div> */}



      <div className="d-flex flex-column justify-content-between pt-2 mb-4">
      {isLoading === true? <Loading/>: isLoading === false && (chartData && (chartData === "" || chartData === null || chartData === undefined || chartData.length === 0))?
      <h1 className="text-center">No data found</h1>: chartData && chartData !== "" && chartData !== null && chartData !== undefined && chartData.length !== 0 &&
            chartData.map((week, index)=> (
              <div className="w-100" key={index+1}>
                {week && week.readings && week.readings.length>0?
                  <>

                  {/*<OverlayChart
                  chartId={"overlay"+ index}
                  unit={unit}
                  data={week.readings[0]}
                  startDate={week.weekStartDate}
                  endDate={week.weekEndDate}
                  />*/}

                  <OverlayTestChart
                  chartId={"overlayTest"+ index}
                  unit={unit}
                  monday={week.readings[0].monday}
                  tuesday={week.readings[0].tuesday}
                  wednesday={week.readings[0].wednessday}
                  thursday={week.readings[0].thursday}
                  friday={week.readings[0].friday}
                  saturday={week.readings[0].saturday}
                  sunday={week.readings[0].sunday}

                  startDate={week.weekStartDate}
                  endDate={week.weekEndDate}
                  />
</>
                  :null}
              </div>
            ))}


      </div>
      </div>
          </div>
        </div>
      </div>
      </div>
    );
};

export default OverlayPage;
