import * as actions from "../actionTypes/blood-glucose";

const initialState = {
  cgmAssessmentReport:[],
  veryHighEventsPercentage: null,
  highEventsPercentage: null,
  inRangeEventsPercentage: null,
  lowEventsPercentage:null,
  veryLowEventsPercentage: null,
  stdDeviation: null,
  historicalReadings:[],
  isLoading: false,
  averageGlucose:null,
  sensorDetails: null,
  carbs:[],
  insulin:[],
  activity:[],
  mbg:null,
  sdbg:null,
  cv:null,
  lage:null,
  cgmHistory:[],
  // ogp:[],
  jIndex:null,
  mage:null,
  mValue:null,
  iqr:null,
  aac:null,
  auc:null,
  hbgi: null,
  adrr: null,


};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_BG_START:
      return{...state,
      isLoading: true}
      break
    case actions.FETCH_BG_SUCCESS:
      return {
        ...state,
        ...action.payLoad,
        historicalReadings: action.payLoad.historicalReadings,
        averageGlucose:action.payLoad.averageGlucose,
        isLoading: false
      }
      break

    case actions.FETCH_BG_FAILED:
      return{
        ...state,
        isLoading: false
      }
      break

    case actions.FETCH_SENSOR_START:
      return{...state,
        isLoading: true}
      break
    case actions.FETCH_SENSOR_SUCCESS:
      return {
        ...state,
        sensorDetails: action.payLoad,
        isLoading: false
      }
      break

    case actions.FETCH_SENSOR_FAILED:
      return{
        ...state,
        isLoading: false
      }
      break

      case actions.FETCH_CARBS_START:
        return{...state,
          isLoading: true}
        break
      case actions.FETCH_CARBS_SUCCESS:
        return {
          ...state,
          carbs: action.payLoad,
          isLoading: false
        }
        break

      case actions.FETCH_CARBS_FAILED:
        return{
          ...state,
          isLoading: false
        }
        break

        case actions.FETCH_INSULIN_START:
          return{...state,
            isLoading: true}
          break
        case actions.FETCH_INSULIN_SUCCESS:
          return {
            ...state,
            insulin: action.payLoad,
            isLoading: false
          }
          break

        case actions.FETCH_INSULIN_FAILED:
          return{
            ...state,
            isLoading: false
          }
          break
          case actions.FETCH_ACTIVITY_START:
            return{...state,
              isLoading: true}
            break
          case actions.FETCH_ACTIVITY_SUCCESS:
            return {
              ...state,
              activity: action.payLoad,
              isLoading: false
            }
            break

          case actions.FETCH_ACTIVITY_FAILED:
            return{
              ...state,
              isLoading: false
            }
            break

            case actions.FETCH_CGM_REPO_SUCCESS:
      return{
        ...state,
        cgmAssessmentReport: action.payLoad.overlay,
        isLoading: false
      }
      break

    case actions.SAVE_MBG_SUCCESS:
      return{
        ...state,
        mbg: action.payLoad,
        isLoading: false
      }
      break

    case actions.SAVE_SDBG_SUCCESS:
      return{
        ...state,
        sdbg: action.payLoad,
        isLoading: false
      }
      break

    case actions.SAVE_CV_SUCCESS:
      return{
        ...state,
        cv: action.payLoad,
        isLoading: false
      }
      break

    case actions.SAVE_LAGE_SUCCESS:
      return{
        ...state,
        lage: action.payLoad,
        isLoading: false
      }
      break

      case actions.SAVE_MAGE_SUCCESS:
        return{
          ...state,
          mage: action.payLoad,
          isLoading: false
        }
        break
      case actions.FETCH_HISTORY_CGM_RECORD_SUCCESS:
        return{
          ...state,
          cgmHistory: action.payLoad,
          isLoading: false
        }


        break

        case actions.SAVE_OGP_SUCCESS:
          return{
            ...state,
            ogp: action.payLoad,
            isLoading: false
          }


          break

          case actions.SAVE_MVALUE_SUCCESS:
            return{
              ...state,
              mValue: action.payLoad,
              isLoading: false
            }


            break
            case actions.SAVE_IQR_SUCCESS:
              return{
                ...state,
                iqr: action.payLoad,
                isLoading: false
              }


              break
              case actions.SAVE_AAC_SUCCESS:
                return{
                  ...state,
                  aac: action.payLoad,
                  isLoading: false
                }


                break
                case actions.SAVE_AUC_SUCCESS:
                  return{
                    ...state,
                    auc: action.payLoad,
                    isLoading: false
                  }


                  break
                  case actions.SAVE_HBGI_SUCCESS:
                    return{
                      ...state,
                      hbgi: action.payLoad,
                      isLoading: false
                    }


                    break
                    case actions.SAVE_ADRR_SUCCESS:
                      return{
                        ...state,
                        adrr: action.payLoad,
                        isLoading: false
                      }


                      break
          case actions.SAVE_JINDEX_SUCCESS:
            return{
              ...state,
              jIndex: action.payLoad,
              isLoading: false
            }


            break

    default:
      return state;
  }
}
