import React from "react";
import ActivityChart from "../Charts/ActivityChart";


 import { useNavigate, useLocation } from "react-router-dom";
const ActivityReport = (props) => {
  const history = useNavigate();
const loc = useLocation();

  return (
    <div className='h-100 d-flex flex-column justify-content-between'>

    <div className="chart-heading mt-3 loginNew-HCP-panel">
      <span className="bg-primary px-2 py-2 font-weight-bold text-white">
        Activity
      </span>
    </div>
    {/* <div>
      <i className="fas fa-ellipsis-h"></i>
    </div> */}



  <div className="d-flex flex-column justify-content-between px-3 mt-3 mb-4">

    <ActivityChart data={props.data}/>

  </div>
  </div>
  );
};

export default ActivityReport;
