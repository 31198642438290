import React from 'react';
import { useDispatch, useSelector} from "react-redux";
import CompareComp from '../Components/Reports/CompareComp';
import Navbar from "../Components/Common/Navbar";
import Sidebar from "../Components/Common/Sidebar";

const AnalyticsPage = () => {
    const userData = useSelector((state) => state.user);

    return (
        <div className="container-fluid h-100 bg-white">


            <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">

        <div className="col-12  bg-faded ">
       <h1 className="text-center">Coming soon!</h1>
         </div>
    </div>
</div>
    );
};

export default AnalyticsPage;
