export const FETCH_PATIENT_START = "FETCH_PATIENT_START"
export const FETCH_PATIENT_SUCCESS = "FETCH_PATIENT_SUCCESS"
export const FETCH_PATIENT_FAILED = "FETCH_PATIENT_FAILED"

export const FETCH_ENGINEER_START = "FETCH_ENGINEER_START"
export const FETCH_ENGINEER_FAILED = "FETCH_ENGINEER_FAILED"
export const FETCH_ENGINEER_SUCCESS = "FETCH_ENGINEER_SUCCESS"

export const FETCH_ADMIN_START = "FETCH_ADMIN_START"
export const FETCH_ADMIN_FAILED = "FETCH_ADMIN_FAILED"
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS"

export const FETCH_CLINICIAN_START = "FETCH_CLINICIAN_START"
export const FETCH_CLINICIAN_FAILED = "FETCH_CLINICIAN_FAILED"
export const FETCH_CLINICIAN_SUCCESS = "FETCH_CLINICIAN_SUCCESS"


export const MAKE_ENGINEER_START = "MAKE_ENGINEER_START"
export const MAKE_ENGINEER_FAILED = "MAKE_ENGINEER_FAILED"
export const MAKE_ENGINEER_SUCCESS = "MAKE_ENGINEER_SUCCESS"

export const MAKE_CLINICIAN_START = "MAKE_CLINICIAN_START"
export const MAKE_CLINICIAN_FAILED = "MAKE_CLINICIAN_FAILED"
export const MAKE_CLINICIAN_SUCCESS = "MAKE_CLINICIAN_SUCCESS"

export const ADD_ENGINEER_START = "ADD_ENGINEER_START"
export const ADD_ENGINEER_FAILED = "ADD_ENGINEER_FAILED"
export const ADD_ENGINEER_SUCCESS = "ADD_ENGINEER_SUCCESS"

export const ADD_CLINICAL_ADMIN_START = "ADD_CLINICAL_ADMIN_START"
export const ADD_CLINICAL_ADMIN_FAILED = "ADD_CLINICAL_ADMIN_FAILED"
export const ADD_CLINICAL_ADMIN_SUCCESS = "ADD_CLINICAL_ADMIN_SUCCESS"

export const ADD_CLINICIAN_START = "ADD_CLINICIAN_START"
export const ADD_CLINICIAN_FAILED = "ADD_CLINICIAN_FAILED"
export const ADD_CLINICIAN_SUCCESS = "ADD_CLINICIAN_SUCCESS"

export const FETCH_CLINICIANS_START = "FETCH_CLINICIANS_START"
export const FETCH_CLINICIANS_FAILED = "FETCH_CLINICIANS_FAILED"
export const FETCH_CLINICIANS_SUCCESS = "FETCH_CLINICIANS_SUCCESS"

export const FETCH_SUBJECTS_START = "FETCH_SUBJECTS_START"
export const FETCH_SUBJECTS_FAILED = "FETCH_SUBJECTS_FAILED"
export const FETCH_SUBJECTS_SUCCESS = "FETCH_SUBJECTS_SUCCESS"

export const FETCH_ALL_SUBJECTS_START = "FETCH_ALL_SUBJECTS_START"
export const FETCH_ALL_SUBJECTS_FAILED = "FETCH_ALL_SUBJECTS_FAILED"
export const FETCH_ALL_SUBJECTS_SUCCESS = "FETCH_ALL_SUBJECTS_SUCCESS"

export const FETCH_ENGINEER_DATA_START = "FETCH_ENGINEER_DATA_START"
export const FETCH_ENGINEER_DATA_FAILED = "FETCH_ENGINEER_DATA_FAILED"
export const FETCH_ENGINEER_DATA_SUCCESS = "FETCH_ENGINEER_DATA_SUCCESS"

export const FETCH_SENSOR_LOGS_DATA_START = "FETCH_SENSOR_LOGS_DATA_START"
export const FETCH_SENSOR_LOGS_DATA_FAILED = "FETCH_SENSOR_LOGS_DATA_FAILED"
export const FETCH_SENSOR_LOGS_DATA_SUCCESS = "FETCH_SENSOR_LOGS_DATA_SUCCESS"

export const FETCH_ENGINEER_SESSIONS_DATA_START = "FETCH_ENGINEER_SESSIONS_DATA_START"
export const FETCH_ENGINEER_SESSIONS_DATA_FAILED = "FETCH_ENGINEER_SESSIONS_DATA_FAILED"
export const FETCH_ENGINEER_SESSIONS_DATA_SUCCESS = "FETCH_ENGINEER_SESSIONS_DATA_SUCCESS"

export const FETCH_CLINICIANS_SESSIONS_DATA_START = "FETCH_CLINICIANS_SESSIONS_DATA_START"
export const FETCH_CLINICIANS_SESSIONS_DATA_FAILED = "FETCH_CLINICIANS_SESSIONS_DATA_FAILED"
export const FETCH_CLINICIANS_SESSIONS_DATA_SUCCESS = "FETCH_CLINICIANS_SESSIONS_DATA_SUCCESS"

export const FETCH_SESSIONS_PARAMS_START = "FETCH_SESSIONS_PARAMS_START"
export const FETCH_SESSIONS_PARAMS_FAILED = "FETCH_SESSIONS_PARAMS_FAILED"
export const FETCH_SESSIONS_PARAMS_SUCCESS = "FETCH_SESSIONS_PARAMS_SUCCESS"

export const FETCH_ENGINEER_MEASUREMENT_DATA_START = "FETCH_ENGINEER_MEASUREMENT_DATA_START"
export const FETCH_ENGINEER_MEASUREMENT_DATA_FAILED = "FETCH_ENGINEER_MEASUREMENT_DATA_FAILED"
export const FETCH_ENGINEER_MEASUREMENT_DATA_SUCCESS = "FETCH_ENGINEER_MEASUREMENT_DATA_SUCCESS"

export const FETCH_SENSOR_LOGS_BY_SESSION_START = "FETCH_SENSOR_LOGS_BY_SESSION_START"
export const FETCH_SENSOR_LOGS_BY_SESSION_FAILED = "FETCH_SENSOR_LOGS_BY_SESSION_FAILED"
export const FETCH_SENSOR_LOGS_BY_SESSION_SUCCESS = "FETCH_SENSOR_LOGS_BY_SESSION_SUCCESS"


export const SAVE_APP_SUCCESS = "SAVE_APP_SUCCESS"
export const SAVE_APP_MODUS_SUCCESS = "SAVE_APP_MODUS_SUCCESS"
