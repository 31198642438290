import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Spin } from "antd";
import { getCliniciansSubjects, getAllSubjects, removeFromClinicalTrial, fetchClinicians, addSubjectsToClinicalTirals } from "../../../redux/actions/admin/admin";
import { useNavigate, useLocation, useParams } from "react-router-dom";
// import {encrypt,decrypt} from "../../../utils/encryption";
import { default as Titles } from "../../../utils/document-titles";
const AllSubjects = (props) => {
document.title = Titles.allSubjects;
    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();
    const {sId} = useParams();
    const [toDelete, setToDelete] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [addModal, setAddModal]= useState(false);
    const [showAddBtn, setShowAddBtn]= useState(false);
    const [subId, setSubId]= useState("");
    const [clinicalId, setClinicalId]= useState("");
    const [subToStop, setSubToStop] = useState("");
    const { userDetails } = useSelector((state) => {return state.user});
    const list = useSelector((state) => {return (state.admin.allSubjects)});
    const clinicsList = useSelector((state) => {return (state.admin.cliniciansData)});

    useEffect(() => {
          fetchClinicians();
      }, []);

    const fetchClinicians = async () => {
      dispatch(getAllSubjects());
        };
    const removingSubjectFromClinicalTrial = async()=>{
          let id = toDelete;
          let result = await dispatch(removeFromClinicalTrial(id));
          if (result === true) {
            setDeleteModal(false);
            fetchClinicians();
          }
        }
        const setClinicId=(event)=>{
          console.log("EVENT :", event.target.value);
          if(event.target.value !==""){
            setShowAddBtn(true);
            setClinicalId(event.target.value);
          }else{
            setShowAddBtn(false);
            setClinicalId("");
          }


        }
        const addSubToCT = async(e)=>{
          e.preventDefault();
          let data = {
            clinicalId: clinicalId,
            subId: subId
          }
          let res = await dispatch(addSubjectsToClinicalTirals(data));
          if(res === true){
            setAddModal(false);
            fetchClinicians();
          }
        }
          if (userDetails &&
             userDetails.user && (userDetails.user.role.includes(6)===true || userDetails.user.role.includes(0)===true || userDetails.user.role.includes(1)===true)) {

    return (
        <div className="container-fluid patient-list">
                <div className="row p-2 px-3">
                    <div className="col-12">
                        <div className="row justify-content-between pl-3">

                            <div className="col-12">
                              <h1 className="pt-3 pb-3 font-weight-bold chart-heading">SUBJECTS</h1>
                              <h3 className="pt-3 pb-3 font-weight-bold chart-heading">You are viewing all subjects.</h3>

                            </div>
                            <div className="col-12">
                            <table className="table table-hover subjects-table" id="task-table">
                              <thead>
                              <tr>
                                <th>Subject ID</th>
                                <th>Clinic ID</th>

                                <th>PID</th>
                                <th>Action</th>

                              </tr>
                              </thead>
                              <tbody style={{overflow: "auto"}}>

                              { list !== null && list.subjects && list.subjects.length > 0 && list.subjects.map((row, index)=>(
                              <tr key={index}>
                                <td>{row.subjectId}</td>
                                <td ><span className={row.clinicalId === "unassigned"?"bg-primary pl-3 pr-3 pt-1 pb-1 text-white":""} style={{borderRadius:"10px"}}>{row.clinicalId}</span></td>

                                <td>

                                {row.pId}
                                </td>
                                <td>
                                {row.clinicalId === "unassigned" ?<button
                                    type="button"
                                    className=""
                                    title="Add to Clinical Trial"

                                    onClick={(event)=>{
                                      setAddModal(true);
                                      setSubId(row.subjectId);

                                    }}
                                >
                                  <span className="">
                                  <i className="fa fa-plus" aria-hidden="true"></i>
                                  </span>
                                </button>:row.clinicalId !== "stopped" ?<button
                                    type="button"
                                    className=""
                                    title="Stop Clinical Trial"
                                    onClick={(event)=>{
                                      setToDelete(row.subjectId);
                                      setDeleteModal(true);
                                      setSubToStop(row.subjectId);

                                    }}
                                >
                                  <span className="">
                                  <i className="fa fa-times" aria-hidden="true"></i>
                                  </span>
                                </button> : null}

                              </td>




                            </tr>
                            ))}

                              </tbody>
                            </table>
                            </div>
                        </div>

                        <div
                          tabIndex="-1"
                          id="myModal-delete"
                          className={`modal  ${
                            deleteModal ? "d-block fading" : " d-none"
                          }`}
                          >
                          <div className="modal-dialog modal-dialog-centered modal-confirm">
                            <div className="modal-content">
                              <div className="modal-header">
                                <div className="icon-box mr-2">
                                  <i className="fas fa-trash-alt text-danger"></i>
                                </div>
                                <h4 className="modal-heading w-100 text-black">
                                  Are you sure?
                                </h4>
                                <button
                                  type="button"
                                  className="close"
                                  onClick={()=>setDeleteModal(false)}
                                >
                                  &times;
                                </button>
                              </div>
                              <div className="modal-body">
                                <p>
                                  Do you really want to stop this "{subToStop}" subject? This
                                  process cannot be undone.
                                </p>
                              </div>
                              <div className="modal-footer justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={()=>setDeleteModal(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => {
                                    removingSubjectFromClinicalTrial();
                                  }}
                                >
                                  Stop
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>
                </div>

            <div
                className={`modal big_modal_dialog ${
                    addModal? "d-block modal-fading" : " d-none"
                }`}
                tabIndex="-1"
                data-backdrop="static"
                data-keyboard="false"

                role="dialog"

                aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addHospitalModalTitle">
                      Add Subject to Clinical Trial
                    </h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={()=>setAddModal(false)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <form className="pl-3 pr-3">
                    <div className="container my-4 ">

                      <div className="row justify-content-center align-items-center mb-3 text-white">

                        <div className="col-12 text-center">
                        <label htmlFor="clinicalTrial" className="text-black">Choose a Clinical Trial</label>

                        <select name="clinicalTrial" id="clinicalTrial" className="text-black ml-2" onChange={(event)=>{setClinicId(event);}}>
                        <option value="" className="text-black">Select</option>
                        {clinicsList.clinics && clinicsList.clinics.length>0 && clinicsList.clinics.map((clnc, index)=>
                          clnc.status && clnc .status !=="1"?<option value={clnc.shortId} className="text-black" key={index}>{clnc.shortId}</option>:null


                        )}

                        </select>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                    <button className={showAddBtn?"btn btn-primary" :"d-none"} onClick={(e)=>{addSubToCT(e)}}>Add Subject </button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
    );
  }

  return (
    <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>

  );
};

export default AllSubjects;
