import * as actions from "../actionTypes/compare.js";

const initialState = {
  veryHighEventsPercentage: null,
  highEventsPercentage: null,
  inRangeEventsPercentage: null,
  lowEventsPercentage:null,
  veryLowEventsPercentage: null,
  stdDeviation: null,
  historicalReadings:[],
  isLoading: false,
  averageGlucose:null,
  sensorDetails: null,
  isLoading: false,
  overlay:[],
  history:[],
  bg:[],
 // averageGlucose:null,
};

export default (state = initialState, action) => {
  switch (action.type) {

      case actions.FETCH_COMPARE_LEFT_OVERLAY_START:
        return{...state,
        isLoading: true}
        break
      case actions.FETCH_COMPARE_LEFT_OVERLAY_SUCCESS:
        return {
          ...state,
          overlay: action.payLoad,
          isLoading: false
        }
        break

      case actions.FETCH_COMPARE_LEFT_OVERLAY_FAILED:
        return{
          ...state,
          isLoading: false
        }
        break
          case actions.FETCH_COMPARE_LEFT_HISTORY_START:
            return{...state,
            isLoading: true}
            break
          case actions.FETCH_COMPARE_LEFT_HISTORY_SUCCESS:
            return {
              ...state,
              history: action.payLoad,
              isLoading: false
            }
            break

          case actions.FETCH_COMPARE_LEFT_HISTORY_FAILED:
            return{
              ...state,
              isLoading: false
            }
            break

              case actions.FETCH_COMPARE_BG_LEFT_START:
                return{...state,
                isLoading: true}
                break
              case actions.FETCH_COMPARE_BG_LEFT_SUCCESS:
                return {
                  ...state,
                  ...action.payLoad,
                  bg:action.payLoad,
                  historicalReadings: action.payLoad.historicalReadings,
                  averageGlucose:action.payLoad.averageGlucose,
                  isLoading: false
                }
                break

              case actions.FETCH_COMPARE_BG_LEFT_FAILED:
                return{
                  ...state,
                  isLoading: false
                }
                break



                  case actions.FETCH_COMPARE_SENSOR_LEFT_START:
                    return{...state,
                    isLoading: true}
                    break
                  case actions.FETCH_COMPARE_SENSOR_LEFT_SUCCESS:
                    return {
                      ...state,
                      sensorDetails: action.payLoad,
                      isLoading: false
                    }
                    break

                  case actions.FETCH_COMPARE_SENSOR_LEFT_FAILED:
                    return{
                      ...state,
                      isLoading: false
                    }
                    break

    default:
      return state;
  }
}
