import * as actions from "../actionTypes";
import { API_BASE_URL, fetchUserURL } from "../../utils/urls";

import http from "../../utils/http-service";
import { loginSuccess,logout } from "./auth";
import { fetchSettings} from "./settings";

import {encrypt,decrypt} from "../../utils/encryption";
import ShowToast from "../../utils/show-toast";



const fetchUserStart = () => {
  return { type: actions.FETCH_USER_START };
};

const fetchUserFailed = () => {
  return { type: actions.FETCH_USER_FAILED };
};

const fetchUserSuccess = (payload) => {
  return { type: actions.FETCH_USER_SUCCESS, payload };
};

export const fetchUser = () => async (dispatch) => {
  try {
    dispatch(fetchUserStart());

    const token = !!localStorage.getItem("EostreAuthToken");
    if (!token) {
      dispatch(fetchUserFailed());
      return;
    }

    const result = await http.get(`${API_BASE_URL}${fetchUserURL}`, {});

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
// console.log("DATA USER", result);
data = await decrypt(data);
data = JSON.parse(data);
let detail = data;
      // if(data && data.user && data.user.role.length>1){
      //   window.history.pushState({}, null,'user/role')
      // }
// window.history.pushState({}, null,'user/role')
      dispatch(loginSuccess({ token: localStorage.getItem("EostreAuthToken") }));
      let refinedUser = {user:{
        address:detail.user.address,
        app:detail.user.app,
        clinicalId:detail.user.clinicalId,
        country:detail.user.country,
        dateOfBirth:detail.user.dateOfBirth,
        diabetesType: detail.user.diabetesType,
        email: detail.user.email,
        firstName: detail.user.firstName,
        gender:detail.user.gender,
        hmac: detail.user.hmac,
        lastName:detail.user.lastName,
        phone:detail.user.phone,
        picture:detail.user.picture,
        postcode:detail.user.postcode,
        role:detail.user.role,
        status:detail.user.status,
        subjectId:detail.user.subjectId,
        town:detail.user.town,
        low:detail.settings.low,
        high: detail.settings.high,
        unit:detail.settings.unit,
      }}
      dispatch(fetchUserSuccess({ user: refinedUser }));
      dispatch(fetchSettings());
    }

    return result;
  } catch (error) {
    dispatch(logout());
    dispatch(fetchUserFailed());
    throw error;
  }
};

const saveSelectedUserStart = () => {
  return { type: actions.SAVE_USER_START };
};

const sveSelectedUserFailed = () => {
  return { type: actions.SAVE_USER_FAILED };
};

const saveSelectedUserSuccess = (payload) => {
  return { type: actions.SAVE_USER_SUCCESS, payload };
};

export const saveUser = (user) => async (dispatch) => {
  try {
      dispatch(saveSelectedUserStart());
      dispatch(saveSelectedUserSuccess({ user: user }));
      window.history.pushState({}, null, '/dashboard');
    return true;
  } catch (error) {
    dispatch(sveSelectedUserFailed());
    throw error;
  }
};

export const updateUserRole = (role)=>{
  // console.log("ROLE in action :", role);
  let apple  = JSON.stringify(role);
  apple = encrypt(apple).then((encrypted)=>{
    // console.log("Encreypted :", encrypted);
    apple = encrypted;
    // console.log("Apple", apple);
    localStorage.setItem("Apple", apple);
  }).catch((error)=>{
    // console.log("ERROR :", error);
  });


  return {type:actions.UPDATE_ROLE, payload:role}
}


const updateUserStart = () => {
  return { type: actions.UPDATE_USER_START };
};

const updateUserFailed = () => {
  return { type: actions.UPDATE_USER_FAILED };
};

const updateUserSuccess = (payload) => {
  return { type: actions.UPDATE_USER_SUCCESS, payload };
};

export const updateUser = (data) => async (dispatch) => {

  // console.log("DATA UPDATE FOR:", data);
  dispatch(updateUserStart());

  try {
    let updateData=data;

    // updateData=JSON.stringify(updateData);

    // updateData = encrypt(updateData).then((encrypted)=>{
    //   updateData = encrypted;
    // });
    try {
    const encryptedData = await encrypt(updateData);
    updateData = encryptedData;
    // console.log('Encrypted data:', updateData);
    // ... continue with your logic
  } catch (error) {
    // console.error('Encryption error:', error);
    // Handle any errors that occurred during encryption
  }

    // console.log('Encrypted data After:', updateData);
    let result = await http.post(`${API_BASE_URL}/user/e_update`, {data:updateData});

    // let gift = decrypt(result.data.data);
    // gift=JSON.stringify(gift)

    let gift = result.data.data;


    dispatch({ type: actions.UPDATE_USER_SUCCESS, payLoad: gift });
    ShowToast(result.data.message, "success");

    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");

    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }else {
      dispatch(updateUserFailed());
      return false;
      throw error;
    }

  }
};
const filterFromDateSuccess = (payload) => {
  return { type: actions.SAVE_FROM_DATE_SUCCESS, payload };
};
export const filterFromDate = (date) => async (dispatch) => {
  // console.log("FROM DATE DATA", date);
  try {

      dispatch(filterFromDateSuccess({ fromDate: date }));

    return true;
  } catch (error) {
  // console.log("ERROR", error.response);
  return false;
  }
};

const filterToDateSuccess = (payload) => {
  return { type: actions.SAVE_TO_DATE_SUCCESS, payload };
};
export const filterToDate = (date) => async (dispatch) => {
  try {

      dispatch(filterToDateSuccess({ toDate: date }));

    return true;
  } catch (error) {
  // console.log("ERROR", error.response);
  return false;
  }
};
