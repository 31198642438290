import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Spin } from "antd";
import { getCliniciansSubjects, saveCliniciansSubjects, removeFromClinicalTrial } from "../../../redux/actions/admin/admin";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import { default as Titles } from "../../../utils/document-titles";

// import {encrypt,decrypt} from "../../../utils/encryption";



import moment from "moment";


const CliniciansSubjects = (props) => {
  document.title = Titles.trialsSubjects;


    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();
    const {sId} = useParams();
    let shortId = sId;
    shortId = shortId.replace(":","");
    // const [role, setRole] = useState("patient");
    const { userDetails } = useSelector((state) => {return state.user});

    const list = useSelector((state) => {return (state.admin.cliniciansSubjects)});




    const [delModal, setDelModal] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);

    const [susModal, setSusModal] = useState(false);
    const [toSuspend, setToSuspend] = useState("");
    const [addModal, setAddModal] = useState(false);
    const [instance, setInstance] = useState("");

    const [editModal, setEditModal] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const [toEdit, setToEdit] = useState("");
    const [selectedRole, setSelectedRole] = useState("");

    // Add Clinician
        const [firstName, setFirstName] = useState("");
        const [lastName, setLastName] = useState("");
        const [gender, setGender] = useState("");
        const [dob, setDob] = useState();
        const [email, setEmail] = useState("");
        const [clinicianApp, setClinicianApp] = useState("");
        const [subToStop, setSubToStop] = useState("");


        const [address, setAddress] = useState("");
        const [town, setTown] = useState("");
        const [postCode, setPostCode] = useState("");
        const [country, setCountry] = useState("");
        const [phone, setPhoneNo] = useState("");



    useEffect(() => {
          fetchClinicians();
      }, []);

      const handleRole =(event)=>{
        setSelectedRole(event.target.value);

        if(event.target.value === ""){
          setShowSave(false);

        }else {
          setShowSave(true);

        }

      }
      const fetchClinicians = async () => {

      dispatch(getCliniciansSubjects(shortId))

        };

        const saveId = async (id, subId) => {

         history(`/admin/CliniciansSessionsData/:${id}/:${subId}/:${shortId}`);



          };

      const removingSubjectFromClinicalTrial = async()=>{
        let id = toDelete;
        let result = await dispatch(removeFromClinicalTrial(id));
        if (result === true) {
          fetchClinicians();
          setDeleteModal(false);


        }
      }

          if (userDetails &&
             userDetails.user && (userDetails.user.role.includes(6)===true || userDetails.user.role.includes(1)===true || userDetails.user.role.includes(0)===true)) {

    return (
        <div className="container-fluid patient-list">
            {/*<div className="container bg-white rounded my-5 p-3">*/}
            {/* <form onSubmit={handleSubmit}> */}
                <div className="row p-2 px-3">
                    <div className="col-12">
                        <div className="row justify-content-between pl-3">

                            <div className="col-12">
                              <h1 className="pt-3 pb-3 font-weight-bold chart-heading">Subject's Details</h1>
                              <h3 className="pt-3 pb-3 font-weight-bold chart-heading">You are viewing the subjects of  "{shortId}".</h3>

                            </div>
                            {/*<div className="col-12 col-md-6 text-right">
                              <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true)}}>Add Clinician</button>
                            </div>*/}
                            <div className="col-12">
                            <table className="table table-hover" id="task-table">
                              <thead>
                              <tr>
                                <th>Subject ID</th>
                                <th>P-ID</th>
                                <th>Status</th>


                                <th>Actions</th>
                              </tr>
                              </thead>
                              <tbody>

                              { list !== null && list.length > 0 && list.map((row, index)=>(
                              <tr>
                                <td>{row.subjectId}</td>
                                <td>{row.pId}</td>
                                <td>{row.clinicalId === "stopped"?<span style={{backgroundColor: "rgba(0,0,0,.35)", padding:"2%", borderRadius:"10px"}}>Stopped</span>:<span>In Progress</span>}</td>


                                <td>


                                  <button
                                      type="button"
                                      className=""
                                      title="View"
                                      onClick={(event)=>{
                                        saveId(row._id, row.subjectId);
                                      }}
                                  >
                                    <span className="">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                    </span>
                                  </button>
                                {row.clinicalId !== "stopped"?
                                  <button
                                      type="button"
                                      className="ml-2"
                                      title="Stop Clinical Trial"

                                      onClick={(event)=>{
                                        // this.updateAdmissionDataStart(`${index}`)
                                        // removingSubjectFromClinicalTrial(row._id);
                                        setSubToStop(row.subjectId);
                                        setToDelete(row.subjectId);
                                        setDeleteModal(true);

                                      }}
                                  >
                                    <span className="" >
                                    <i className="fa fa-times" aria-hidden="true"></i>

                                    </span>
                                  </button>
                                  :null}
                                </td>





                              </tr>
                            ))}

                              </tbody>
                            </table>
                            </div>
                            <div
                tabIndex="-1"
                id="myModal-delete"
                className={`modal  ${
                  deleteModal ? "d-block fading" : " d-none"
                }`}
              >
                <div className="modal-dialog modal-dialog-centered modal-confirm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="icon-box mr-2">
                        <i className="fas fa-trash-alt text-danger"></i>
                      </div>
                      <h4 className="modal-heading w-100 text-black">
                        Are you sure?
                      </h4>
                      <button
                        type="button"
                        className="close"
                        onClick={()=>setDeleteModal(false)}
                      >
                        &times;
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        Do you really want to stop this "{subToStop}" subject? This
                        process cannot be undone.
                      </p>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={()=>setDeleteModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          removingSubjectFromClinicalTrial();
                        }}
                      >
                        Stop
                      </button>
                    </div>
                  </div>
                </div>
              </div>


                            {/*<div className={delModal? "modal d-block fading" : "modal d-none"}>
                                <div className="modal-dialog modal-dialog-centered modal-confirm">
                                  <div className="modal-content">
                                    <div className="modal-header flex-column">
                                      <div className="icon-box">
                                        <i className="fas fa-trash-alt text-danger"></i>
                                      </div>
                                      <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                      <button type="button" className="close" onClick={()=>{setDelModal(false)}} >&times;</button>
                                    </div>
                                    <div className="modal-body">
                                    <p>Do you really want to delete this account? <strong>This will also delete all the devices and readings of this patient</strong>. Type "<strong>{instance.email}</strong>" to confirm deletion.</p>

                                      <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Email"
                                      id="title"

                                      required
                                    />
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                      <button type="button" className="btn btn-secondary" onClick={()=>{setDelModal(false)}}>Cancel</button>

                                        <button type="button" className="btn btn-danger"  >Delete</button>

                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className={susModal? "modal d-block fading" : "modal d-none"}>
                                  <div className="modal-dialog modal-dialog-centered modal-confirm">
                                    <div className="modal-content">
                                      <div className="modal-header flex-column">
                                        <div className="icon-box">
                                          <i className="fas fa-trash-alt text-danger"></i>
                                        </div>
                                        <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                        <button type="button" className="close" onClick={()=>{setSusModal(false)}} >&times;</button>
                                      </div>
                                      <div className="modal-body">
                                        <p>Do you really want to suspend this records? This process cannot be undone.</p>
                                      </div>
                                      <div className="modal-footer justify-content-center">
                                        <button type="button" className="btn btn-secondary" onClick={()=>{setSusModal(false)}}>Cancel</button>
                                        <button type="button" className="btn btn-danger" >Suspend</button>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div className={editModal? "modal d-block fading" : "modal d-none"}>
                                    <div className="modal-dialog modal-dialog-centered modal-confirm">
                                      <div className="modal-content">
                                        <div className="modal-header flex-column">
                                          <div className="icon-box">
                                            <i className="fas fa-edit text-primary"></i>
                                          </div>
                                          <h4 className="modal-heading w-100 text-black">Change Role</h4>
                                          <button type="button" className="close" onClick={()=>{setEditModal(false)}} >&times;</button>
                                        </div>
                                        <div className="modal-body">
                                        <label htmlFor="roles">Choose a New Role &nbsp; </label>

                                        <select name="roles" id="roles" onChange={(e)=>{handleRole(e)}}>
                                          <option value="">Select</option>
                                          <option value="clinician">Clinician</option>
                                          <option value="patient">Patient</option>

                                        </select>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                          {showSave?<button type="button" className="btn btn-secondary" onClick={()=>{assignRole()}}>Save</button>: null}

                                        </div>
                                      </div>
                                    </div>

                                </div> */}

                        </div>

                    </div>
                </div>
            {/*</div>*/}
        </div>
    );
  }

  return (
    <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>

  );
};

export default CliniciansSubjects;
