import http from "../../utils/http-service";
import { API_BASE_URL, fetchBgDataUrl } from "../../utils/urls";
import {
  FETCH_OVERLAY_START,
  FETCH_OVERLAY_FAILED,
  FETCH_OVERLAY_SUCCESS,
} from "../actionTypes/overlay";
import moment from "moment";

import {encrypt, decrypt} from "../../utils/encryption";

export const fetchOverlayData = (data) => async(dispatch) => {
  console.log("DATA OVERLAY", data);
  dispatch({
    type: FETCH_OVERLAY_START,
  });
  let dataForChart=[];
  let count;
  let week;
  let days;
  let orgData = [];
  let cgm_start;
  let daysDif=1;
  let unit = data.unit;
try {

    let overlayTimeEnc;
    let res;
    if(data.time){
      if(data.time === "30days") {
      cgm_start = moment().subtract(30, 'days');


      cgm_start = new Date(cgm_start._d);
      daysDif = 30;

    }else if(data.time==="7days") {

      cgm_start = moment().subtract(7, 'days');


      cgm_start = new Date(cgm_start._d);
daysDif = 7;

    } else if (data.time === "14days") {


      cgm_start = moment().subtract(14, 'days');

      cgm_start = new Date(cgm_start._d);
      daysDif = 14;

    } else if (data.time === "90days") {

      cgm_start = moment().subtract(90, 'days');


      cgm_start = new Date(cgm_start._d);
      daysDif = 90;

    }
      overlayTimeEnc = data.time;

      overlayTimeEnc =await encrypt(overlayTimeEnc);

      let encType = 1;
      encType = JSON.stringify(encType);
      encType =await encrypt(encType);

     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${encType}?time=${overlayTimeEnc}`);

    } else if(data.start_date) {
      console.log("Start date case");
      cgm_start = data.start_date;
      let overlay_start = data.start_date;
      let overlay_end = data.end_date;
      let a  = moment(overlay_end);
      let b  = moment(overlay_start);
      if(data.start_date === data.end_date){

        console.log("Equal" , data.start_date, data.end_date);
        daysDif = 1;
      } else {
      daysDif = a.diff(b, 'days');
      console.log("DAYS DIF", daysDif);
    }
      overlay_start =await encrypt(overlay_start);
      overlay_end =await encrypt(overlay_end);
      let encType = 1;
      encType = JSON.stringify(encType);
      encType =await encrypt(encType);

     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${encType}?start_date=${overlay_start}&end_date=${overlay_end}`,);

}
let receivedData = res.data.data;
receivedData = await decrypt(receivedData);
receivedData = JSON.parse(receivedData);
console.log("receivedData",receivedData);


// Commenting to test other code

let weeksArr=[];


    if(res.status===200){
      // console.log("IN Process", res);
      if(res.data && res.data.data && receivedData.historicalReadings && receivedData.historicalReadings.length>0){
        let data = receivedData.historicalReadings;
        let firstWeekStartDate = moment(cgm_start);
        let numberOfWeeks = Math.ceil(daysDif / 7);
        console.log("numberOfWeeks", numberOfWeeks);
        let weekStartDate="";
        let weekEndDate="";
        for(let i=0; i<numberOfWeeks; i++){
          if(i === 0){
            if(new Date(data.start_date) === new Date(data.end_date)){
              // console.log("Start", data.startDate);
              // console.log("End", data.endDate);

              weekStartDate= moment(firstWeekStartDate).startOf('day')._d;
              weekEndDate = moment(firstWeekStartDate).endOf('day')._d;
              // console.log("weekStartDate", weekStartDate);
              // console.log("weekEndDate", weekEndDate);

            } else {
              weekStartDate= firstWeekStartDate;
              weekEndDate = moment(firstWeekStartDate).add(7, 'days');
            }

          }
          // let week=[];
          const week = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: []
};
          if (data && data.length > 0) {
            let weekSD = weekStartDate;
            let weekED = weekEndDate;

            data.map((day, index)=> {
              // console.log("Day", day);
            let p1 = moment(weekSD);
// console.log("point1", weekSD);
            let p2 = moment(day.time);
            // console.log("day reading time", day.time);

            let p3 = moment(weekED);
            // console.log("point3", weekED);

            if (p2.isAfter(p1) && p2.isBefore(p3)){
              const dayOfWeek = new Date(day.time).getDay();
  switch (dayOfWeek) {
    case 0:
      week.sunday.push(day);
      break;
    case 1:
      week.monday.push(day);
      break;
    case 2:
      week.tuesday.push(day);
      break;
    case 3:
      week.wednesday.push(day);
      break;
    case 4:
      week.thursday.push(day);
      break;
    case 5:
      week.friday.push(day);
      break;
    case 6:
      week.saturday.push(day);
      break;
    default:
      break;
  }

//               let timeUTC = day.time;
//               let conTime = moment(timeUTC).format("HH:mm");
//               let obj = {time: new Date(timeUTC), [(moment(day.time).utc().format('dddd')).toLowerCase()]: unit===1?((day.value*18.01).toFixed(1)):day.value.toFixed(1), time2:conTime};
// console.log("OBJ", obj);
              // week.push(obj);
            } else {
              // console.log("NOT BETWEEN", day.time);
            }
            // console.log("week", week);
          })
          }
          let obj = {weekStartDate:new Date(weekStartDate).toString(), weekEndDate: new Date(weekEndDate).toString(),
            readings:[week]}
          weeksArr.push(obj);

            weekStartDate= moment(weekEndDate).add(1, 'days');
            weekEndDate = moment(weekEndDate).add(7, 'days');

        };


      }
    };
console.log("WEEK ARRAY", weeksArr);


// Other version of code is

// let weeksArr = [];
//
// if (res.status === 200) {
//   if (res.data && res.data.data && res.data.data.historicalReadings && res.data.data.historicalReadings.length > 0) {
//     const data = res.data.data.overlay;
//     const firstWeekStartDate = moment(cgm_start);
//     const numberOfWeeks = Math.ceil(daysDif / 7);
//
//     for (let i = 0; i < numberOfWeeks; i++) {
//       const weekStartDate = moment(firstWeekStartDate).add(i * 7, 'days');
//       const weekEndDate = moment(weekStartDate).add(6, 'days');
//       const week = [];
//
//       if (data && data.length > 0) {
//         data.forEach(day => {
//           const p1 = moment(weekStartDate);
//           const p2 = moment(day.time);
//           const p3 = moment(weekEndDate);
//
//           if (p2.isBetween(p1, p3, null, '[]')) {
//             const timeUTC = day.time;
//             const conTime = moment(timeUTC).format("HH:mm");
//             const obj = {
//               time: moment(timeUTC).format("DD-MM-YYYY"),
//               [moment(day.time).format('dddd').toLowerCase()]: day.value,
//               time2: conTime
//             };
//             week.push(obj);
//           }
//         });
//       }
//
//       const obj = {
//         weekStartDate: new Date(weekStartDate).toString(),
//         readings: [week]
//       };
//       weeksArr.push(obj);
//     }
//   }
// }
//
// // console.log("WEEK ARRAY", weeksArr);

// receivedData = decrypt(receivedData);
// receivedData = JSON.parse(receivedData);

  // updateUnit(unit, res.receivedData.data)
  dispatch({ type: FETCH_OVERLAY_SUCCESS, payLoad: weeksArr });

  return weeksArr;
} catch (error) {

  dispatch({
    type: FETCH_OVERLAY_FAILED,
  });
}
};
//////////////////////////////////////////















//
//
//
//
// import http from "../../utils/http-service";
// import { API_BASE_URL, fetchBgDataUrl } from "../../utils/urls";
// import {
//   FETCH_OVERLAY_START,
//   FETCH_OVERLAY_FAILED,
//   FETCH_OVERLAY_SUCCESS,
// } from "../actionTypes/overlay";
// import moment from "moment"
// // import {encrypt, decrypt} from "../../../utils/encryption";
//
// export const fetchOverlayData = (data) => async(dispatch) => {
//   dispatch({
//     type: FETCH_OVERLAY_START,
//   });
//   let dataForChart=[];
//   let count;
//   let week;
//   let days;
//   let orgData = [];
// try {
//
//     let overlay;
//     let res;
//     if(data.time){
//       overlay = data.time;
//
//       // bg = encrypt(bg);
//
//       let type = 4;
//       // type = JSON.stringify(type);
//       // type = encrypt(type);
//
//      res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${type}?time=${overlay}`);
//
//     } else if(data.start_date) {
//
//       let overlay_start = data.start_date;
//       // bg_start = encrypt(bg_start);
//       let overlay_end = data.end_date;
//       // bg_end = encrypt(bg_end);
//
//       let type = 4;
//       // type = JSON.stringify(type);
//       // type = encrypt(type);
//
//      res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${type}?start_date=${overlay_start}&end_date=${overlay_end}`,);
// }
// let receivedData = res.data.data;
//
// // console.log("GIFT :", receivedData);
// if (receivedData.overlay) {
//   // // // console.log(data.overlay.length);
//   let overlayLength = receivedData.overlay.length;
// if(receivedData.overlay && receivedData.overlay.length > 0)  {
//   let date1 = new Date(receivedData.overlay[0].time);
//   let date2 = new Date(receivedData.overlay[overlayLength-1].time);
//   let time1 = date1.getTime();
//     let time2 = date2.getTime();
//
//     // // // console.log( parseInt((time1-time2)/(24*3600*1000)));
//     days = parseInt((time1-time2)/(24*3600*1000));
//     // console.log("DAYS" ,days);
//     let weeks = parseInt(days / 7);
//     week = days%7;
//     // console.log("WEEK :", week);
//     if(week != 0){
//       weeks = weeks +1
//     };
//
//     // // // console.log("total weeks: ", weeks);
//     let startDate = new Date(receivedData.overlay[0].time).getDate();
//     // console.log("startDate", startDate);
//     let endDate = startDate + 6;
//     let weekStartDate = new Date(receivedData.overlay[0].time);
//     // console.log("weekStartDate", weekStartDate);
//
//
//     let weekEndDate = new Date(receivedData.overlay[0].time);
//     weekEndDate.setDate(endDate);
//     // console.log("weekEndDate", weekEndDate);
//     //// // // console.log("week start data: ", weekStartDate);
//     //// // // console.log("week end date: ", new Date(receivedData.overlay[0].time));
//     for (let day = 1; day <= weeks; day++){
//       // console.log("DAY", day);
//       let tempData = [];
//       receivedData.overlay.forEach((item, i) => {
//         //// // // console.log("record: ", item);
//         if(new Date(item.time) >= weekStartDate && new Date(item.time) <= weekEndDate){
//           tempData.push(item);
//         }
//       });
//       dataForChart = tempData;
//       // let tempDate = weekStartDate.getDate() - 7;
//       // let tempDate2 = weekStartDate.getDate() - 1;
//       // weekEndDate = weekStartDate;
//       // weekEndDate.setDate(tempDate2);
//
//
//       let tempDate = weekEndDate.getDate() + 1;
//       // console.log("tempDate", tempDate);
//       weekStartDate = weekEndDate;
//       // console.log("After First Itration week start Date:", weekStartDate);
//       weekStartDate.setDate(tempData);
//       // console.log("After First Itration week start Date with +1:", weekStartDate);
//
//       weekStartDate.setDate(tempDate);
//       weekEndDate = new Date(weekStartDate);
//       let tempDate1 = weekStartDate.getDate() + 1;
//       weekEndDate.setDate(tempDate1);
//       // console.log("WEEK END DATE", weekEndDate);
//     }
//     // console.log("data for chart: ", dataForChart);
//     let tempChartData = [];
//     for (let x = 0; x< dataForChart.length; x++) {
//       let calculatedDate = new Date(dataForChart[x].time);
//       // // // console.log(new Date(dataForChart[x].time).getDay());
//       let chartObj = {};
//       if (calculatedDate.getDay() === 1){
//         // console.log(new Date(dataForChart[x].time).getHours());
//         let hours = new Date(dataForChart[x].time).getHours();
//         let minutes = new Date(dataForChart[x].time).getMinutes();
//         let monday = dataForChart[x].value;
//         chartObj.date = hours + ":" + minutes;
//         chartObj.monday = monday;
//         //let obj ={
//
//           //monday: monday,
//         //}
//         //// // // console.log("OBJ", obj);
//       } else if(calculatedDate.getDay() === 2) {
//         let hours = dataForChart[x].time.getHours();
//         let minutes = dataForChart[x].time.getMinutes();
//         let tuesday = dataForChart[x].value
//         // let obj ={
//         //
//         //   tuesday: tuesday,
//         // }
//         chartObj.date = hours + ":" + minutes;
//         chartObj.tuesday = tuesday;
//       }else if(calculatedDate.getDay() === 3) {
//         let hours = new Date(dataForChart[x].time).getHours();
//         let minutes = new Date(dataForChart[x].time).getMinutes();
//         // // // console.log("VALUES Wed", dataForChart[x].value);
//
//         let wednessday = dataForChart[x].value
//         // let obj ={
//         //
//         //   wednessday: wednessday,
//         // }
//         chartObj.date = hours + ":" + minutes;
//         chartObj.wednessday = wednessday;
//         // // // // console.log(obj);
//         // orgData.push(obj)
//         // setOrganizedData(obj);
//       }else if(calculatedDate.getDay() === 4) {
//         let hours = new Date(dataForChart[x].time).getHours();
//         let minutes = new Date(dataForChart[x].time).getMinutes();
//         // // // console.log("VALUES Thu", dataForChart[x].value);
//
//         let thursday = dataForChart[x].value
//         // let obj ={
//         //
//         //   thursday: thursday,
//         // }
//         chartObj.date = hours + ":" + minutes;
//         chartObj.thursday = thursday;
//         // // // // console.log(obj);
//         // orgData.push(obj)
//         // setOrganizedData(obj);
//       }else if(calculatedDate.getDay() === 5) {
//         let hours = new Date(dataForChart[x].time).getHours();
//         let minutes = new Date(dataForChart[x].time).getMinutes();
//         // // // console.log("VALUES Fri", dataForChart[x].value);
//
//         let friday = dataForChart[x].value
//         // let obj ={
//         //
//         //   friday: friday,
//         // }
//         chartObj.date = hours + ":" + minutes;
//         chartObj.friday = friday;
//
//         // // // // console.log(obj);
//         // orgData.push(obj)
//         // setOrganizedData(obj);
//       }else if(calculatedDate.getDay() === 6) {
//         let hours = new Date(dataForChart[x].time).getHours();
//         let minutes = new Date(dataForChart[x].time).getMinutes();
//         // // // console.log("VALUES Sat", dataForChart[x].value);
//         let saturday = dataForChart[x].value
//
//         // let obj ={
//         //
//         //   saturday: saturday,
//         // }
//         chartObj.date = hours + ":" + minutes;
//         chartObj.saturday = saturday;
//
//         // // // // console.log(obj);
//         // orgData.push(obj)
//         // // // console.log(orgData);
//
//         // setOrganizedData(obj);
//       }
//       tempChartData.push(chartObj);
//
//       // dataForChart[x].time.getDay()
//     }
//     // console.log("record tempChartData: ", tempChartData);
//     // localStorage.setItem("chartData", JSON.stringify(tempChartData));
//     dataForChart = tempChartData;
//     // console.log("dataForChart", dataForChart);
//
//
//
//     // setOrganizedData(tempChartData);
//     //// // // console.log("Data", organizedData);
//     // setOrganizedData(orgData);
//
//   // var dataForChart = receivedData.overlay.map((reading)=>{
//   //   return {
//   //     time: reading.time,
//   //     value: reading.value,
//   //   }
//   // });
//   // setChartReadings(dataForChart)
//   // let cData = receivedData.overlay;
//   // for (let i = 0; i < cData.length; i++) {
//   //
//   // }
// }
// }
//
// // receivedData = decrypt(receivedData);
// // receivedData = JSON.parse(receivedData);
//
//   // updateUnit(unit, res.receivedData.data)
//   dispatch({ type: FETCH_OVERLAY_SUCCESS, payLoad: dataForChart });
//
//   return dataForChart;
// } catch (error) {
//
//   dispatch({
//     type: FETCH_OVERLAY_FAILED,
//   });
// }
// };
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
