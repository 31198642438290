import React, { useEffect, useState } from "react";
// import { useLocation } from 'react-router-dom';
import {useSelector} from 'react-redux';
import Loading from '../../Common/Loading';
import insulinIcon from "../../../Assets/charts/insulin.svg";
import exerciseIcon from "../../../Assets/charts/exercise.svg";
import dietIcon from "../../../Assets/charts/diet.svg";
import medicineIcon from "../../../Assets/charts/medicine.svg";
import moment from "moment";
import * as _ from "lodash";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
// Themes end
function OverlayTestChart(props) {
  const [showGraph, setShowGraph]=useState(false);
  const [count, setCount]=useState(props.unit);

  const {user, settings} = useSelector((state)=>{return state});
console.log("PROPS BG:", props);
  // let chart;
  // const location = useLocation();
// // // // // console.log("props.",props);
  useEffect(() => {
let chart = am4core.create(props.chartId, am4charts.XYChart);

    const transformHistoricalReadings = (data) => {

      let transformedData = [];

      transformedData = data && data.length>0 && data.map((reading) => {


                return {
                  date: new Date(reading.time),
                  reading: reading.value,
                };
          });
      let myArray = _.sortBy(transformedData, function(dateObj) {

        return new Date(dateObj.date);
      });

      return myArray;
    };



// Add data
// chart.data = props.data;
let thursdaySeriesData ;
// = props.thursday;
// // // // console.log("thursdaySeriesData", thursdaySeriesData);
if(props.thursday && props.thursday.length>0){
   thursdaySeriesData = transformHistoricalReadings(props.thursday);
   thursdaySeriesData = thursdaySeriesData.map((record)=>{
      return {
        time: new Date(record.date),
        glucose: count===1? (18.01*record.reading): record.reading,
        time2: moment(record.date).format("HH:mm"),
      }
  });
}
console.log("thursdaySeriesData", thursdaySeriesData);
let saturdaySeriesData ;
if(props.saturday && props.saturday.length>0){
  saturdaySeriesData = transformHistoricalReadings(props.saturday);

   saturdaySeriesData = saturdaySeriesData.map((record)=>{
      return {
        time: new Date(record.date),
        glucose: count===1? (18.01*record.reading): record.reading,
        time2: moment(record.date).format("HH:mm"),
      }
    });
}else {
  console.log("Saturday");
}
// // // console.log("saturdaySeriesData", saturdaySeriesData);
let sundaySeriesData ;
if(props.sunday && props.sunday.length>0){
  sundaySeriesData = transformHistoricalReadings(props.sunday);

   sundaySeriesData = sundaySeriesData.map((record)=>{
      return {
        time: new Date(record.date),
        glucose: count===1? (18.01*record.reading): record.reading,
        time2: moment(record.date).format("HH:mm"),
      }
    });
    }


let mondaySeriesData ;
if(props.monday && props.monday.length>0){
  mondaySeriesData = transformHistoricalReadings(props.monday);

   mondaySeriesData = mondaySeriesData.map((record)=>{
      return {
        time: new Date(record.date),
        glucose: count===1? (18.01*record.reading): record.reading,
        time2: moment(record.date).format("HH:mm"),
      }
    });
  }

let tuesdaySeriesData ;
if(props.tuesday && props.tuesday.length>0){
  tuesdaySeriesData = transformHistoricalReadings(props.tuesday);

   tuesdaySeriesData = tuesdaySeriesData.map((record)=>{
      return {
        time: new Date(record.date),
        glucose: count===1? (18.01*record.reading): record.reading,
        time2: moment(record.date).format("HH:mm"),
      }
    });
  }

  let wednesdaySeriesData ;
  if(props.wednesday && props.wednesday.length>0){
    wednesdaySeriesData = transformHistoricalReadings(props.wednesday);

     wednesdaySeriesData = wednesdaySeriesData.map((record)=>{
        return {
          time: new Date(record.date),
          glucose: count===1? (18.01*record.reading): record.reading,
          time2: moment(record.date).format("HH:mm"),
        }
      });
    }
    let fridaySeriesData ;
    if(props.friday && props.friday.length>0){
      fridaySeriesData = transformHistoricalReadings(props.friday);

       fridaySeriesData = fridaySeriesData.map((record)=>{
          return {
            time: new Date(record.date),
            glucose: count===1? (18.01*record.reading): record.reading,
            time2: moment(record.date).format("HH:mm"),
          }
        });
      }


// let fridayData = transformHistoricalReadings(props.friday);
// console.log("fridayData", fridayData);
// chart.data = fridayData && fridayData.length > 0 && fridayData.map(record => {
// // chart.data = customeData.map(record => {
//
// let color= record.reading>=user.userDetails.user.high? "#f38f20": record.reading<= user.userDetails.user.low ? "#d61a00": "#2a93fc";
//
//   return {
//     time: new Date(record.date),
//     glucose: count===1? (18.01*record.reading): record.reading,
//     time2: moment(record.date).format("HH:mm"),
//
//   }
// });
// console.log("CHART DATA :", chart.data);
// let dataLength = chart.data.length - 1;


chart.dateFormatter.dateFormat = "dd-MM-yyyy";
  // Create axes
let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
// dateAxis.startLocation = 0.5;
dateAxis.dateFormatter = new am4core.DateFormatter();
dateAxis.dateFormatter.dateFormat = "dd-YYYY";
dateAxis.cursorTooltipEnabled = false;



// Create value axis
let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;
valueAxis.title.text = user.userDetails && user.userDetails.user && user.userDetails.user.unit === 0?"mmol/L":"mg/dL";
// valueAxis.title.text = count === 0?"mmol/L":"mg/dL";

valueAxis.cursorTooltipEnabled = false;

// let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
// valueAxis2.renderer.opposite = true;
// valueAxis2.syncWithAxis = valueAxis;
// valueAxis2.hidden = true;
// valueAxis2.cursorTooltipEnabled = false;








// for monday Series
let mondaySeries = chart.series.push(new am4charts.LineSeries());
mondaySeries.dataFields.valueY = "glucose";
mondaySeries.dataFields.dateX = "time";
mondaySeries.strokeWidth = 4;
mondaySeries.fillOpacity = 0;
mondaySeries.name = "Monday";
mondaySeries.sequencedInterpolation = false;
mondaySeries.data= mondaySeriesData;


// mondaySeries.yAxis = valueAxis2;
let mondayBullet = mondaySeries.bullets.push(new am4charts.Bullet());
mondayBullet.stroke = am4core.color("#000");
mondayBullet.radius = 4;
mondayBullet.tooltipText = count === 0?"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L":"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mg/dL";


// for tuesday Series
let tuesdaySeries = chart.series.push(new am4charts.LineSeries());
tuesdaySeries.dataFields.valueY = "glucose";
tuesdaySeries.dataFields.dateX = "time";
tuesdaySeries.strokeWidth = 4;
tuesdaySeries.fillOpacity = 0;
tuesdaySeries.name = "Tuesday";
tuesdaySeries.sequencedInterpolation = false;
tuesdaySeries.data= tuesdaySeriesData;


// tuesdaySeries.yAxis = valueAxis2;
let tuesdayBullet = tuesdaySeries.bullets.push(new am4charts.Bullet());
tuesdayBullet.stroke = am4core.color("#000");
tuesdayBullet.radius = 4;
tuesdayBullet.tooltipText = count === 0?"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L":"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mg/dL";



// for wednesday Series
let wednesdaySeries = chart.series.push(new am4charts.LineSeries());
wednesdaySeries.dataFields.valueY = "glucose";
wednesdaySeries.dataFields.dateX = "time";
wednesdaySeries.strokeWidth = 4;
wednesdaySeries.fillOpacity = 0;
wednesdaySeries.name = "Wednesday";
wednesdaySeries.sequencedInterpolation = false;
wednesdaySeries.data= wednesdaySeriesData;


// wednesdaySeries.yAxis = valueAxis2;
let wednesdayBullet = wednesdaySeries.bullets.push(new am4charts.Bullet());
wednesdayBullet.stroke = am4core.color("#000");
wednesdayBullet.radius = 4;
wednesdayBullet.tooltipText = count === 0?"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L":"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mg/dL";



// for thursday Series
let thursdaySeries = chart.series.push(new am4charts.LineSeries());
thursdaySeries.dataFields.valueY = "glucose";
thursdaySeries.dataFields.dateX = "time";
thursdaySeries.strokeWidth = 4;
thursdaySeries.fillOpacity = 0;
thursdaySeries.name = "Thursday";
thursdaySeries.sequencedInterpolation = false;
thursdaySeries.data= thursdaySeriesData;


// thursdaySeries.yAxis = valueAxis2;
let thursdayBullet = thursdaySeries.bullets.push(new am4charts.Bullet());
thursdayBullet.stroke = am4core.color("#000");
thursdayBullet.radius = 4;
thursdayBullet.tooltipText = count === 0?"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L":"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mg/dL";


// Create series for friday

let fridaySeries = chart.series.push(new am4charts.LineSeries());
fridaySeries.dataFields.valueY = "glucose";
fridaySeries.dataFields.dateX = "time";
fridaySeries.strokeWidth = 4;
fridaySeries.fillOpacity = 0;
fridaySeries.name = "Friday";
fridaySeries.sequencedInterpolation = false;
fridaySeries.data= fridaySeriesData;

let bullet = fridaySeries.bullets.push(new am4core.Circle());
    bullet.stroke = am4core.color("#fff");
    bullet.radius = 4;
    bullet.propertyFields.fill = "color";
    // bullet.tooltipText = user.userDetails && user.userDetails.user && user.userDetails.user.unit === 0?"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L":"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mg/dL";

    bullet.tooltipText = count === 0?"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L":"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mg/dL";


// for saturday Series
let saturdaySeries = chart.series.push(new am4charts.LineSeries());
saturdaySeries.dataFields.valueY = "glucose";
saturdaySeries.dataFields.dateX = "time";
saturdaySeries.strokeWidth = 4;
saturdaySeries.fillOpacity = 0;
saturdaySeries.name = "Saturday";
saturdaySeries.sequencedInterpolation = false;
saturdaySeries.data= saturdaySeriesData;


// saturdaySeries.yAxis = valueAxis2;
let saturdayBullet = saturdaySeries.bullets.push(new am4charts.Bullet());
saturdayBullet.stroke = am4core.color("#000");
saturdayBullet.radius = 4;
saturdayBullet.tooltipText = count === 0?"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L":"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mg/dL";

// for sunday Series
let sundaySeries = chart.series.push(new am4charts.LineSeries());
sundaySeries.dataFields.valueY = "glucose";
sundaySeries.dataFields.dateX = "time";
sundaySeries.strokeWidth = 4;
sundaySeries.fillOpacity = 0;
sundaySeries.name = "Sunday";
sundaySeries.sequencedInterpolation = false;
sundaySeries.data= sundaySeriesData;


// sundaySeries.yAxis = valueAxis2;
let sundayBullet = sundaySeries.bullets.push(new am4charts.Bullet());
sundayBullet.stroke = am4core.color("#000");
sundayBullet.radius = 4;
sundayBullet.tooltipText = count === 0?"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L":"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mg/dL";


/* Create legend and enable default markers */
chart.legend = new am4charts.Legend();
// chart.legend.useDefaultMarker = true;

/* Remove square from marker template */
let marker = chart.legend.markers.template;
marker.disposeChildren();
marker.width = 40;
marker.height = 40;




chart.scrollbarX = new am4core.Scrollbar();
chart.scrollbarX.parent = chart.bottomAxesContainer;

chart.scrollbarX.thumb.minWidth = 5;
chart.scrollbarX.startGrip.disabled = true;
chart.scrollbarX.endGrip.disabled = true;
// chart.scrollbarX.startGrip.width=10;
// chart.scrollbarX.endGrip.width=10;

let range = valueAxis.axisRanges.create();
range.value = 0;
range.endValue = user.userDetails && user.userDetails.user && user.userDetails.user.low?user.userDetails.user.low:0;
// // // // console.log("user.userDetails.low", user.userDetails.low);
range.axisFill.fill = am4core.color("#ff7dc561");
range.axisFill.fillOpacity = 0.2;
range.grid.strokeOpacity = 0;

let range1 = valueAxis.axisRanges.create();
range1.value = user.userDetails && user.userDetails.user && user.userDetails.user.high?user.userDetails.user.high:0;
range1.endValue = 30;
range1.axisFill.fill = am4core.color("#ffff008c");
range1.axisFill.fillOpacity = 0.2;
range1.grid.strokeOpacity = 0;

chart.cursor = new am4charts.XYCursor();
// chart.cursor.behavior= 'panXY';

chart.events.on('ready', () => {
  setShowGraph(true);
});
return () => {
      chart.dispose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.data, props.friday, props.exercise, props.medicine, props.thursday, settings, ]);

  return (<div className="">
  <div className={showGraph?"d-none":" w-100"}>                    <Loading/>
</div>
        <div id={props.chartId} className={showGraph?"":"d-none"} style={{ width: "100%", minHeight: "500px" }}></div>
  </div>
  );
}
export default OverlayTestChart;
