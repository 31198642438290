import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Spin } from "antd";
import { getPatients, makingEngineer,makingEngineerAdmin, makingClinicalAdmin, removePatient, suspendUser, activateUser, makingAdmin, removeRole } from "../../../../redux/actions/admin/admin";

 import { useNavigate, useLocation } from "react-router-dom";
// import {encrypt,decrypt} from "../../../utils/encryption";
import UserDetails from "./userstatchart.js";
import countryList from './CountriesList';


import moment from "moment";

// import UsersMangement from "../../common/nav/usersmanagement.js";

const Patients = (props) => {

    const dispatch = useDispatch();
    const history = useNavigate();

    const [patientsList, setPatientsList] = useState([]);
    // const [role, setRole] = useState("patient");
    const list = useSelector((state) => {return (state.admin.patients)});
    const {role} = useSelector((state) => {return (state.user.userDetails.user)});
    const {userDetails} = useSelector((state) => {return (state.user)});






    const [delModal, setDelModal] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [showDelBtn, setShowDelBtn] = useState(false);

    const [actModal, setActModal] = useState(false);
    const [toActivate, setToActivate] = useState("");

    const [removeRoleModal, setRemoveRoleModal] = useState(false);
    const [toRoleRemove, setToRoleRemove] = useState("");
    const [toRoleRemoveId, setToRoleRemoveId] = useState("");


    const [susModal, setSusModal] = useState(false);
    const [toSuspend, setToSuspend] = useState("");
    const [addModal, setAddModal] = useState(false);
    const [instance, setInstance] = useState("");

    const [editModal, setEditModal] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const [toEdit, setToEdit] = useState("");
    const [searchText, setSearchText] = useState("");
    const [searchPatients, setSearchPatients] = useState([]);


    const [selectedRole, setSelectedRole] = useState("");




    useEffect(() => {
          fetchPatients();
      }, [props.role]);
      useEffect(() => {
            // // console.log("instance", instance);
        }, [instance]);

      const handleRole =(event)=>{
        setSelectedRole(event.target.value);
        // // console.log("event.target.value", event.target.value);
        setShowSave(true);

      }
      const fetchPatients = async () => {
      dispatch(getPatients(4,props.app))

        };

        const assignRole = async () => {

          if(selectedRole==="engineer"){
        dispatch(makingEngineer(toEdit));
        setEditModal(false);
      } else if(selectedRole==="admin") {
        dispatch(makingAdmin(toEdit));
        setEditModal(false);

      } else if(selectedRole==="clinicalAdmin"){
        dispatch(makingClinicalAdmin(toEdit));
        setEditModal(false);

      }else if(selectedRole==="engineerAdmin"){
        dispatch(makingEngineerAdmin(toEdit));
        setEditModal(false);
      }


          };
      const searchInPatients=()=>{
        let searched=[];


        searched =  list.filter(obj => {
          if(
          obj.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
          obj.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          obj.dateOfBirth.toLowerCase().includes(searchText.toLowerCase()) ||
          obj.gender.toLowerCase().includes(searchText.toLowerCase()) ||
          obj.postcode.toLowerCase().includes(searchText.toLowerCase())||
          obj.address.toLowerCase().includes(searchText.toLowerCase())||
          obj.country.toLowerCase().includes(searchText.toLowerCase())||
          obj.town.toLowerCase().includes(searchText.toLowerCase())||
          obj.phone.toLowerCase().includes(searchText.toLowerCase())||
          obj.email.toLowerCase().includes(searchText.toLowerCase())){
            return obj;
          }


        });


        setSearchPatients(searched);
          }

          const removingPatient=async()=>{
          const res =  await dispatch(removePatient(toDelete));
          if(res===true){
            setDelModal(false);
            fetchPatients();

          }
          }

          const suspendingUser=async()=>{
          const res =  await dispatch(suspendUser(toSuspend));
          if(res===true){
            setSusModal(false);
            fetchPatients();

          }
          }
          const activatingUser=async()=>{
          const res =  await dispatch(activateUser(toActivate));
          if(res===true){
            setActModal(false);
            fetchPatients();

          }
          }
          const chekEmailForDelBtn =(e)=>{
            if(e.target.value === instance.email){
              // // console.log("YES");
              setShowDelBtn(true);
            } else {
              setShowDelBtn(false);

            }

          }
          const removingRole = async () => {

            if(selectedRole==="engineer"){
          dispatch(removeRole(toRoleRemove,3));
          setRemoveRoleModal(false);
          } else if(selectedRole==="admin") {
          dispatch(removeRole(toRoleRemove,1));
          setRemoveRoleModal(false);

          } else if(selectedRole==="clinicalAdmin"){
          dispatch(removeRole(toRoleRemove,6));
          setRemoveRoleModal(false);

          }else if(selectedRole==="engineerAdmin"){
          dispatch(removeRole(toRoleRemove, 5));
          setRemoveRoleModal(false);
          }else if(selectedRole==="patient"){
          dispatch(removeRole(toRoleRemove, 4));
          setRemoveRoleModal(false);
          }

          setShowSave(false);
          fetchPatients();

            };

            const handleRemoveRole =(event)=>{
              setSelectedRole(event.target.value);
              // // console.log("event.target.value", event.target.value);
              setShowSave(true);

            }
    return (
        <div className="container-fluid patient-list">
            {/*<div className="container bg-white rounded my-5 p-3">*/}
            {/* <form onSubmit={handleSubmit}> */}
                <div className="row p-2 px-3">
                    <div className="col-12">
                        <div className="row justify-content-between pl-3">

                            <div className="col-12">
                              <h1 className="pt-3 pb-3 font-weight-bold chart-heading">Patient's Details</h1>
                            </div>
                            <div className="col-12 text-right pb-5">
                            {/* <label htmlhtmlFor="search">Search</label>*/}
                            <input
                              type="text"
                              className="p-2"
                              placeholder="Search"
                              id="search"
                              defaultValue={searchText}
                              onChange={(e) => setSearchText(e.target.value)}

                            />
                            <button type="button" className="btn btn-primary ml-2" onClick={()=>searchInPatients()}>Search</button>
                            </div>
                            <div className="col-12">
                            <table className="table table-hover" id="task-table">
                              <thead>
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>DOB</th>

                                <th>Email</th>
                                <th>Joined At</th>
                                <th>Status</th>

                                <th>Country</th>
                                <th>City</th>
                                <th>Phone</th>


                                <th>Actions</th>
                              </tr>
                              </thead>
                              <tbody>
                              {searchPatients.length === 0 && searchText==="" && list !== null && list.length > 0 && list.map((row, index)=>(
                              <tr key={index}>
                                <td>{row.firstName}</td>
                                <td>{row.lastName}</td>
                                <td>{moment(row.dateOfBirth).format("D MMM YYYY")}</td>
                                <td>{row.email}</td>
                                <td>{moment(row.createdAt).format("D MMM YYYY")}</td>
                                <td>{row.status}</td>
                                <td>{row.country}</td>
                                <td>{row.city}</td>
                                <td>{row.phone}</td>
                                <td className="text-left">

                                {row.role.length !== 5 ?
                                  <button
                                      type="button"
                                      className="mr-2"
                                      data-toggle="modal"
                                      data-target="#addHospitalModal"
                                      onClick={(event)=>{
                                        // this.updateAdmissionDataStart(`${index}`)
                                        setInstance(row)

                                        setEditModal(true);
                                        setToEdit(row._id);

                                      }}
                                  >
                                    <span className="">
                                    <i className="fas fa-edit text-primary"></i>
                                    </span>
                                  </button>:null}

                                  {userDetails && userDetails.user && userDetails.user.role.includes(0)===true?
                                                                    <button
                                                                        type="button"
                                                                        className=" mr-2"
                                                                        onClick={()=>{
                                                                          setToDelete(`${row._id}`);
                                                                          setInstance(row);
                                                                          setDelModal(true);}}
                                                                          >
                                                                        <span className="">
                                                                        <i className="fas fa-trash-alt text-danger"></i>
                                                                        </span>
                                                                    </button>:null}
                                                                    {userDetails && userDetails.user && userDetails.user.role.includes(0)===true && row.status === "active"?

                                                                    <button
                                                                        type="button"
                                                                        className=" mr-2"
                                                                        onClick={()=>{
                                                                          setToSuspend(`${row._id}`);
                                                                          setSusModal(true);
                                                                      }}
                                                                    >
                                                                                        <span className="">
                                                                                        <i className="fa fa-ban" aria-hidden="true"></i>

                                                                                        </span>
                                                                    </button>:null}

                                                                    {userDetails && userDetails.user && userDetails.user.role.includes(0)===true && row.status!== "active"?

                                                                    <button
                                                                        type="button"
                                                                        className=" mr-2"
                                                                        onClick={()=>{
                                                                          setToActivate(`${row._id}`);
                                                                          setActModal(true);
                                                                      }}
                                                                    >
                                                                    <span className="">
                                                                    <i className="fa fa-check"></i>

                                                                    </span>
                                                                    </button>:null}
                                                                    {userDetails && userDetails.user && userDetails.user.role.includes(0)===true ?
                                                                    <button
                                                                        type="button"
                                                                        className=" mr-2"
                                                                        onClick={()=>{
                                                                          setInstance(row)
                                                                          setRemoveRoleModal(true);
                                                                          setToRoleRemove(row._id);
                                                                      }}
                                                                    >
                                                                                        <span className="">
                                                                                        <i className="fa fa-users" aria-hidden="true"></i>


                                                                                        </span>
                                                                    </button>:null}


                                </td>




                              </tr>
                            ))}
                            { searchPatients !== null && searchPatients.length > 0 && searchPatients.map((row, index)=>(
                            <tr key={index}>
                              <td>{row.firstName}</td>
                              <td>{row.lastName}</td>
                              <td>{moment(row.dateOfBirth).format("D MMM YYYY")}</td>
                              <td>{row.email}</td>
                              <td>{moment(row.createdAt).format("D MMM YYYY")}</td>
                              <td>{row.status}</td>
                              <td>{row.country}</td>
                              <td>{row.city}</td>
                              <td>{row.phone}</td>
                              <td className="text-left">

                              {row.role.length !== 5 ?
                                <button
                                    type="button"
                                    className="mr-2"
                                    data-toggle="modal"
                                    data-target="#addHospitalModal"
                                    onClick={(event)=>{
                                      // this.updateAdmissionDataStart(`${index}`)
                                      setInstance(row)
                                      setEditModal(true);
                                      setToEdit(row._id);

                                    }}
                                >
                                  <span className="">
                                  <i className="fas fa-edit text-primary"></i>
                                  </span>
                                </button>
                                :null}

                                {userDetails && userDetails.user && userDetails.user.role.includes(0)===true?
                                                                  <button
                                                                      type="button"
                                                                      className=" mr-2"
                                                                      onClick={()=>{
                                                                        setToDelete(`${row._id}`);
                                                                        setInstance(row);
                                                                        setDelModal(true);}}
                                                                        >
                                                                      <span className="">
                                                                      <i className="fas fa-trash-alt text-danger"></i>
                                                                      </span>
                                                                  </button>:null}
                                                                  {userDetails && userDetails.user && userDetails.user.role.includes(0)===true && row.status === "active"?

                                                                  <button
                                                                      type="button"
                                                                      className=" mr-2"
                                                                      onClick={()=>{
                                                                        setToSuspend(`${row._id}`);
                                                                        setSusModal(true);
                                                                    }}
                                                                  >
                                                                                      <span className="">
                                                                                      <i className="fa fa-ban" aria-hidden="true"></i>

                                                                                      </span>
                                                                  </button>:null}
                                                                  {userDetails && userDetails.user && userDetails.user.role.includes(0)===true && row.status!== "active"?

                                                                  <button
                                                                      type="button"
                                                                      className=" mr-2"
                                                                      onClick={()=>{
                                                                        setToActivate(`${row._id}`);
                                                                        setActModal(true);
                                                                    }}
                                                                  >
                                                                  <span className="">
                                                                  <i className="fa fa-check"></i>

                                                                  </span>
                                                                  </button>:null}
                                                                    {userDetails && userDetails.user && userDetails.user.role.includes(0)===true ?
                                                                  <button
                                                                      type="button"
                                                                      className=" mr-2"
                                                                      onClick={()=>{
                                                                        setInstance(row)
                                                                        setRemoveRoleModal(true);
                                                                        setToRoleRemove(row._id);
                                                                    }}
                                                                  >
                                                                                      <span className="">
                                                                                      <i className="fa fa-users" aria-hidden="true"></i>


                                                                                      </span>
                                                                  </button>:null}

                              </td>




                            </tr>
                            ))}
                              </tbody>
                            </table>
                            </div>
                            <div className={delModal? "modal d-block fading" : "modal d-none"}>
                                <div className="modal-dialog modal-dialog-centered modal-confirm">
                                  <div className="modal-content">
                                    <div className="modal-header flex-column">
                                      <div className="icon-box">
                                        <i className="fas fa-trash-alt text-danger"></i>
                                      </div>
                                      <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                      <button type="button" className="close" onClick={()=>{setDelModal(false); setShowDelBtn(false)}} >&times;</button>
                                    </div>
                                    <div className="modal-body">
                                    <p>Do you really want to delete this account? <strong>This will also delete all the devices and readings of this patient</strong>. Type "<strong>{instance.email}</strong>" to confirm deletion.</p>

                                      <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Email"
                                      id="title"
                                      onChange={(e)=>{chekEmailForDelBtn(e)}}
                                      required
                                    />
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                      <button type="button" className="btn btn-secondary" onClick={()=>{setDelModal(false);setShowDelBtn(false)}}>Cancel</button>

                                        {showDelBtn?<button type="button" className="btn btn-danger" onClick={()=>{removingPatient()}} >Delete</button>:null}

                                    </div>
                                  </div>
                                </div>

                              </div>

                              <div className={removeRoleModal? "modal d-block fading" : "modal d-none"}>
                                  <div className="modal-dialog modal-dialog-centered modal-confirm">
                                    <div className="modal-content">
                                      <div className="modal-header flex-column">
                                        <div className="icon-box">
                                          <i className="fas fa-edit text-primary"></i>
                                        </div>
                                        <h4 className="modal-heading w-100 text-black">Change Roles</h4>
                                        <button type="button" className="close" onClick={()=>{setRemoveRoleModal(false)}} >&times;</button>
                                      </div>
                                      <div className="modal-body">
                                      <label htmlFor="roles">Select a Role to be removed &nbsp; </label>

                                      <select name="roles" id="roles" onChange={(e)=>{handleRemoveRole(e)}}>
                                        <option value=""></option>
                                        {/*<option value="clinician">Clinician</option>*/}

                                        {instance!== "" && instance!== null && instance.role.includes(1) === true?

                                        <option value="admin">Admin</option>
                                        :null}

                                        {instance!== "" && instance!== null && instance.role.includes(3) === true?

                                        <option value="engineer">Engineer</option>
                                        :null}

                                        {instance!== "" && instance!== null && instance.role.includes(4) === true?

                                        <option value="patient">Patient</option>
                                        :null}
                                        {instance!== "" && instance!== null && instance.role.includes(5) === true?

                                        <option value="engineerAdmin">Engineer Admin</option>
                                        :null}
                                        {instance!== "" && instance!== null && instance.role.includes(6) === true?

                                        <option value="clinicalAdmin">Clinical Admin</option>
                                        :null}

                                      </select>
                                      </div>
                                      <div className="modal-footer justify-content-center">
                                        {showSave?<button type="button" className="btn btn-secondary" onClick={()=>{removingRole()}}>Remove</button>: null}

                                      </div>
                                    </div>
                                  </div>

                                </div>
                              <div className={susModal? "modal d-block fading" : "modal d-none"}>
                                  <div className="modal-dialog modal-dialog-centered modal-confirm">
                                    <div className="modal-content">
                                      <div className="modal-header flex-column">
                                        <div className="icon-box">
                                          <i className="fas fa-ban text-danger"></i>
                                        </div>
                                        <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                        <button type="button" className="close" onClick={()=>{setSusModal(false)}} >&times;</button>
                                      </div>
                                      <div className="modal-body">
                                        <p>Do you really want to suspend this records? This process cannot be undone.</p>
                                      </div>
                                      <div className="modal-footer justify-content-center">
                                        <button type="button" className="btn btn-secondary" onClick={()=>{setSusModal(false)}}>Cancel</button>
                                        <button type="button" className="btn btn-danger" onClick={()=>{suspendingUser()}} >Suspend</button>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div className={actModal? "modal d-block fading" : "modal d-none"}>
                                    <div className="modal-dialog modal-dialog-centered modal-confirm">
                                      <div className="modal-content">
                                        <div className="modal-header flex-column">
                                          <div className="icon-box">
                                            <i className="fas fa-ban text-danger"></i>
                                          </div>
                                          <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                          <button type="button" className="close" onClick={()=>{setActModal(false)}} >&times;</button>
                                        </div>
                                        <div className="modal-body">
                                          <p>Do you really want to Activate this user? This process cannot be undone.</p>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                          <button type="button" className="btn btn-secondary" onClick={()=>{setActModal(false)}}>Cancel</button>
                                          <button type="button" className="btn btn-danger" onClick={()=>{activatingUser()}} >Activate</button>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                <div className={editModal? "modal d-block fading" : "modal d-none"}>
                                    <div className="modal-dialog modal-dialog-centered modal-confirm">
                                      <div className="modal-content">
                                        <div className="modal-header flex-column">
                                          <div className="icon-box">
                                            <i className="fas fa-edit text-primary"></i>
                                          </div>
                                          <h4 className="modal-heading w-100 text-black">Change Role</h4>
                                          <button type="button" className="close" onClick={()=>{setEditModal(false)}} >&times;</button>
                                        </div>
                                        <div className="modal-body">
                                        <label htmlFor="roles">Choose a New Role &nbsp; </label>

                                        <select name="roles" id="roles" onChange={(e)=>{handleRole(e)}}>
                                          <option value=""></option>
                                          {/*<option value="clinician">Clinician</option>*/}

                                          {instance!== "" && instance!== null && instance.role.includes(1) === false && role.includes(0)=== true?

                                          <option value="admin">Admin</option>
                                          :null}

                                          {instance!== "" && instance!== null && instance.role.includes(3) === false?

                                          <option value="engineer">Engineer</option>
                                          :null}

                                          {instance!== "" && instance!== null && instance.role.includes(4) === false && instance.role.includes(1) === false?

                                          <option value="patient">Patient</option>
                                          :null}
                                          {instance!== "" && instance!== null && instance.role.includes(5) === false && instance.role.includes(1) === false?

                                          <option value="engineerAdmin">Engineer Admin</option>
                                          :null}
                                          {instance!== "" && instance!== null && instance.role.includes(6) === false?

                                          <option value="clinicalAdmin">Clinical Admin</option>
                                          :null}

                                        </select>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                          {showSave?<button type="button" className="btn btn-secondary" onClick={()=>{assignRole()}}>Save</button>: null}

                                        </div>
                                      </div>
                                    </div>

                                  </div>
                        </div>

                    </div>
                </div>
            {/*</div>*/}
        </div>
    );
};

export default Patients;
