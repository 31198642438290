import React,{useEffect} from 'react';
import { useDispatch, useSelector} from "react-redux";
import CompareComp from '../Components/Reports/CompareComp';
import Navbar from "../Components/Common/Navbar";
import Sidebar from "../Components/Common/Sidebar";

const ErrorPage = () => {
    const {userDetails} = useSelector((state) => state.user);
    useEffect(()=>{
      // console.log("userDetails", userDetails);
    },[userDetails])

    return (

        <div className="container-fluid h-100 bg-white">
        {userDetails && userDetails.user && (userDetails.user.role.includes(0)===true || userDetails.user.role.includes(1)===true)?


            <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">

        <div className="col-12  bg-faded ">
       <h1 className="text-center">Coming soon!</h1>
         </div>
    </div>:<div className="row mw-100 m-auto">
      <div className="col-12">
      <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>
      </div></div>}
</div>
    );
};

export default ErrorPage;
