import React from "react";
import * as _ from 'lodash';

const AverageGlucoseReport = (props) => {
  console.log("PROPS IN AVG", props);
  // console.log("PROPS IN AVG unit", props.unit);


  let avgGlu;
if(props.avg && props.avg !== undefined && props.avg.averageGlucose && props.avg.averageGlucose !== ""){
  avgGlu = _.round(props.avg.averageGlucose, 1).toFixed(1);
}else {
  avgGlu= 0;
}

  // let lastGlu=_.round(props.avg.lastReading.value, 1).toFixed(1);
let lastAvg= props.avg.lastReading;
let unit = props.unit;


  return (

        <div className='h-100 d-flex flex-column justify-content-between'>

            {/*<div className="chart-heading mt-3 loginNew-HCP-panel d-flex">


            </div>*/}
            {/* <div>
              <i className="fas fa-ellipsis-h"></i>
            </div> */}
            <div className="d-flex mt-3">
          <div className="text-center w-50">
          <p className="bg-primary px-2 py-2 font-weight-bold text-white">
            Last Reading
          </p>
          <p className=" font-blue font-size4 font-weight-bold mb-0" style={{ fontSize: "1.5rem !important"}}>
          {lastAvg && lastAvg !== null && lastAvg !== undefined && lastAvg !== ""? unit===0?lastAvg.toFixed(1): (18.01*(lastAvg.toFixed(1))): null}</p><span className="font-gray font-weight-bold unit" style={{ fontSize: "1rem !important"}}>{unit === 0?"mmol/L":"mg/dL"}</span>
          </div>
          <div className="text-center w-50">
          <p className="bg-primary px-2 py-2 font-weight-bold text-white ml-auto">
            Average
          </p>
          <p className=" font-blue font-size4 font-weight-bold mb-0" style={{ fontSize: "1.5rem !important"}}>
          {avgGlu}</p><span className="font-gray font-weight-bold unit" style={{ fontSize: "1rem !important"}}>{unit === 0?"mmol/L":"mg/dL"}</span>
          </div>
          </div>
          <div className="d-flex justify-content-between px-3 mb-4">
            <div className=" text-center">
              <p className='font-size-small font-weight-bold'>Standard <br/>Devltaion</p>
              <p className="font-size1 font-weight-bold d-inline text-danger">{_.round(props.avg.stdDeviation, 1).toFixed(1)}<span><i className="fas fa-long-arrow-alt-up"></i></span></p>
              <p className="font-size-small font-weight-bold font-gray">{unit === 0?"mmol/L":"mg/dL"}</p>
            </div>

            <div className=" text-center">
              <p className='font-size-small font-weight-bold'>Estimated <br/>A1C</p>
              <p className="font-size1 font-weight-bold d-inline text-orange">N/A</p>

              {/*<p className="font-size1 font-weight-bold d-inline text-success">
              2.6<span><i className="fas fa-long-arrow-alt-down"></i></span>
              </p>*/}
              <p className="font-size-small font-weight-bold font-gray">{unit === 0?"mmol/L":"mg/dL"}</p>
            </div>

            <div className=" text-center">
              <p className='font-size-small font-weight-bold'>Average <br/>Devltaion</p>
              <p className="font-size1 font-weight-bold d-inline text-orange">N/A</p>
              <p className="font-size-small font-weight-bold font-gray">{unit === 0?"mmol/L":"mg/dL"}</p>
            </div>
          </div>
          </div>



  );
};

export default AverageGlucoseReport;
